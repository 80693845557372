import { useState, ReactNode, FC } from 'react';
import CaretDown from '../icons/general/CaretDown/CaretDown';

import cls from './style.module.scss';

type AccordionProps = {
	title: string;
	accordionContent: ReactNode;
	rightContentLine1?: string;
	rightContentLine2?: string;
	titleClassName?: string;
	caretClassName?: string;
	rightContentLine1ClassName?: string;
	rightContentLine2ClassName?: string;
	isNegative?: boolean;
};

const Accordion: FC<AccordionProps> = ({
	title,
	accordionContent,
	rightContentLine1,
	rightContentLine2,
	titleClassName,
	caretClassName,
	rightContentLine1ClassName,
	rightContentLine2ClassName,
	isNegative,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={cls.accordion}>
			<div className={cls.accordionHeader} onClick={toggleAccordion}>
				<span className={`${cls.accordionToggleIcon} ${caretClassName} ${isOpen ? cls.rotate : ''}`}>
					<CaretDown />
				</span>
				<h3 className={`${cls.accordionTitle} ${titleClassName}`}>{title}</h3>
				{rightContentLine1 && rightContentLine2 && (
					<div className={cls.accordionRightContent}>
						<div className={`${cls.accordionRightLine1} ${rightContentLine1ClassName}`}>
							{rightContentLine1}:
						</div>
						<div
							className={`${cls.accordionRightLine2} ${
								isNegative ? cls.negative : ''
							} ${rightContentLine2ClassName}`}
						>
							{rightContentLine2}
						</div>
					</div>
				)}
			</div>
			{isOpen && <div className={cls.accordionContent}>{accordionContent}</div>}
		</div>
	);
};

export default Accordion;
