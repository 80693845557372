import { FC, SVGProps } from 'react';

interface TotopIconProps extends SVGProps<SVGSVGElement> {}

const TotopIcon: FC<TotopIconProps> = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M13.8281 12.1886H2.57812C2.50937 12.1886 2.45312 12.2448 2.45312 12.3136V13.2511C2.45312 13.3198 2.50937 13.3761 2.57812 13.3761H13.8281C13.8969 13.3761 13.9531 13.3198 13.9531 13.2511V12.3136C13.9531 12.2448 13.8969 12.1886 13.8281 12.1886ZM6.25 5.09015H7.40469V10.3761C7.40469 10.4448 7.46094 10.5011 7.52969 10.5011H8.46719C8.53594 10.5011 8.59219 10.4448 8.59219 10.3761V5.09015H9.75C9.85469 5.09015 9.9125 4.96984 9.84844 4.88859L8.09844 2.67296C8.08674 2.65802 8.0718 2.64594 8.05475 2.63763C8.03769 2.62932 8.01897 2.625 8 2.625C7.98103 2.625 7.96231 2.62932 7.94525 2.63763C7.92819 2.64594 7.91326 2.65802 7.90156 2.67296L6.15156 4.88702C6.0875 4.96984 6.14531 5.09015 6.25 5.09015V5.09015Z"
			fill="#722ED1"
		/>
	</svg>
);

export default TotopIcon;
