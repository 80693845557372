import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { translate } from 'localizations';
import { AccessRights } from 'store/user/user.types';
import { CallInitialStateType, CallTagType, CallTranslatedText, CommunicationType } from 'store/calls/calls.types';
import DialogItem from './DialogItem';
import ChatItem from './ChatItem';
import CallBodyCallTopTranslate from '../CallBodyCallTopTranslate/CallBodyCallTopTranslate';
import { NewTagComponent } from 'shared/ui';
import { ColoredWords } from './types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { callsActions } from 'store/calls';
import { useGetTagDetailedInfo } from 'pages/Calls/hooks/useGetTagDetailedInfo';
import { RouteParams } from 'pages/Settings/types';
import { useParams } from 'react-router-dom';
import { TagGroup } from 'widgets/ManualTagging/types';

interface IOwnProps {
	language: string;

	onMouseUpFunction?(event: React.MouseEvent<HTMLDivElement>): void;

	accessRights: AccessRights | null;
	currentCall: CallInitialStateType['currentCall'];
	callId: string;
	fragments?: CallTagType[];
	innerRef: React.RefObject<HTMLDivElement>;
	// TOOD: Fix any types
	classes: any;
	solo?: boolean;
	audioPlayerRef: any;
	languagesList: { value: string; label: string }[];
	callTranslated: CallTranslatedText[] | null;
	isCallLoading: boolean;
	getTranslate: (callIdForTranslate: string, languageCode: string) => void;
	communicationType: CommunicationType;
	hasAccessToTags: boolean;
	tagsAreActive?: boolean;
	deleteTag?: (id: string) => Promise<void>;
	tagGroup: TagGroup | null;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
}

const CallBodyDialog: React.FC<IOwnProps> = ({
	language,
	onMouseUpFunction,
	classes,
	accessRights,
	solo,
	currentCall,
	callId,
	fragments,
	audioPlayerRef,
	languagesList,
	innerRef,
	callTranslated,
	isCallLoading,
	getTranslate,
	communicationType,
	hasAccessToTags,
	deleteTag,
	tagGroup,
	clickedTagId,
	handleClickTagSettings,
	tagsAreActive = true,
}) => {
	const dispatch = useAppDispatch();
	const isCallHasText = currentCall && currentCall.stt && currentCall?.stt?.fragments?.length > 0;
	const [lang, setLang] = useState<{ value: string; label: string } | null>(null);

	const translatedComments = useAppSelector((state) => state.calls.translatedComments);

	const { userId } = useParams<RouteParams>();

	// запрашиваем детальную информацию только тега фрагмента в теле коммуникации

	const { data } = useGetTagDetailedInfo(callId, tagGroup === 'fragmentBody' ? clickedTagId : null);
	const { tagDetailedInfo, isLoadingTagDetailedInfo, isErrorTagDetailedInfo } = data;

	// Проверка на наличие текущего callId в translatedComments
	useEffect(() => {
		const translatedComment = translatedComments.find((comment) => comment.callId === callId.toLocaleLowerCase());
		if (translatedComment) {
			const languageValue = languagesList.find((lang) => lang.value === translatedComment.langValue);
			if (languageValue) {
				setLang(languageValue);
				getTranslate(callId, translatedComment.langValue);
			}
		}
	}, []);

	const getTranslateHandle = (callIdForTranslate: string, languageValue: { value: string; label: string }) => {
		setLang(languageValue);
		dispatch(callsActions.setTranslatedComments([{ callId: callIdForTranslate, langValue: languageValue.value }]));
		getTranslate(callIdForTranslate, languageValue.value);
	};

	const TextComponent = communicationType === 'chat' ? ChatItem : DialogItem;
	const dialogTitle =
		communicationType === 'call' ? translate('translateCall', language) : translate('chatText', language);

	const [coloredWords, setColoredWords] = useState<ColoredWords>({
		fragmentIndex: -1,
		matchData: null,
	});

	const getBgHover = useCallback((matchData: string | null, index: number) => {
		setColoredWords({ fragmentIndex: index, matchData });
	}, []);

	return (
		<div className={classes.callBodyDialogWrapper} ref={innerRef}>
			{accessRights?.audio || accessRights?.admin || solo ? (
				<div>
					{currentCall && currentCall.stt && !isCallLoading ? (
						<div className={classes.cbDialog}>
							<div className={classes.cbDialogItems} id={callId}>
								{isCallHasText && (
									<CallBodyCallTopTranslate
										title={dialogTitle}
										language={language}
										callId={callId}
										languagesList={languagesList}
										langItem={lang}
										callTranslated={callTranslated}
										getTranslateHandle={getTranslateHandle}
									/>
								)}
								{isCallHasText ? (
									currentCall.stt.fragments.map((phrase, i, array) => {
										const uniqueFragmentNames = new Set();

										return (
											<div
												className={classes.cbPgrase}
												id={`${phrase.id}-phrase`}
												key={`${phrase.id}-phrase`}
											>
												<div className={classes.cbPgraseDialod}>
													<TextComponent
														audioPlayerRef={audioPlayerRef}
														prevFragment={
															array[i - 1]
																? array[i - 1]
																: {
																		direction:
																			phrase.direction === 'client'
																				? 'operator'
																				: 'client',
																  }
														}
														phrase={phrase}
														callId={callId}
														fragmentIndex={i}
														coloredWords={coloredWords}
														onMouseUpFunction={onMouseUpFunction}
													/>
													{callTranslated && (
														<div className={classes.callTranslated}>
															{callTranslated[i].text}
														</div>
													)}
												</div>
												{hasAccessToTags && (
													<div className={classes.cbFragments}>
														{fragments?.map((fragment) => {
															// Вместо уникальности только по имени, добавляем уникальность по комбинации name + value
															const uniqueFragmentKey = `${fragment?.name}-${fragment?.value}`;

															if (
																phrase.id === fragment.fragment &&
																!uniqueFragmentNames.has(uniqueFragmentKey)
															) {
																uniqueFragmentNames.add(uniqueFragmentKey);

																return (
																	<div
																		key={uniqueFragmentKey} // Используем уникальный ключ для каждого фрагмента
																		onMouseEnter={
																			fragment.matchData
																				? () =>
																						getBgHover(
																							fragment.matchData,
																							i,
																						)
																				: undefined
																		}
																		onMouseLeave={
																			fragment.matchData
																				? () => {
																						getBgHover(null, i);
																				  }
																				: undefined
																		}
																	>
																		<NewTagComponent
																			isStack
																			theme="purple"
																			id={fragment.id}
																			key={uniqueFragmentKey}
																			name={fragment.name}
																			value={fragment.value}
																			accessRights={accessRights?.manual_tagging}
																			language={language}
																			cursor={
																				!tagsAreActive ? 'default' : 'pointer'
																			}
																			settings={(solo || true) && true}
																			deleteTag={deleteTag}
																			handleClickTag={handleClickTagSettings}
																			isActiveTag={
																				clickedTagId === fragment.id &&
																				tagGroup === 'fragmentBody'
																			}
																			isLoading={isLoadingTagDetailedInfo}
																			isErrorTagDetailedInfo={
																				isErrorTagDetailedInfo
																			}
																			fragmentInfo={tagDetailedInfo}
																			userId={userId}
																			tagGroup="fragmentBody"
																		/>
																	</div>
																);
															}
															return null;
														})}
													</div>
												)}
											</div>
										);
									})
								) : (
									<Typography>{translate('noCallTextMessage', language)}</Typography>
								)}
							</div>
						</div>
					) : (
						<div>
							{isCallLoading && (
								<div style={{ textAlign: 'center', height: '100%' }}>
									<CircularProgress size={80} />
								</div>
							)}
							{(currentCall && currentCall.stt === null && (
								<div style={{ textAlign: 'center' }}>
									<CircularProgress size={80} />
								</div>
							)) ||
								(currentCall === false && (
									<div>
										<Typography>{translate('somethingWentWrong', language)}</Typography>
									</div>
								))}
						</div>
					)}
				</div>
			) : (
				<Typography>You can&apos;t see text</Typography>
			)}
		</div>
	);
};

export default React.memo(CallBodyDialog);
