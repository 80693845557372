import { FC, useState } from 'react';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { deleteTemplate } from 'store/search/search.slice';
import { TemplateType } from 'store/search/search.types';
import { ModalWindow } from 'shared/ui';
import { Page } from 'components/common/Criterias/CriteriasList';
import { useAPIResponseToasts } from 'hooks';

const useStyles = makeStyles({
	mwTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '15px',
	},
	mwTitleText: {
		fontWeight: '700 !important',
	},
	mwIconButton: {},
	mwText: {
		color: '#738094 !important',
	},
	mwHelp: {
		marginBottom: '35px !important',
	},
	mwButtonBox: {
		marginTop: '25px',
		textAlign: 'right',
	},
});

type CreateNameTemplateMWPropsType = {
	isOpen: boolean;
	handleClose: () => void;
	currentTemplate: TemplateType | null;
	removeAllCriteriasHandler: (page: Page) => void;
};

const CreateNameTemplateModalWindow: FC<CreateNameTemplateMWPropsType> = ({
	isOpen,
	handleClose,
	currentTemplate,
	removeAllCriteriasHandler,
}) => {
	const dispatch = useAppDispatch();
	const classes = useStyles();
	const { language } = useAppSelector((state) => state.lang);
	const allTemplates = useAppSelector((state) => state.search.calls.allTemplates);
	const [loading, setLoading] = useState<boolean>(false);
	const handleNotifications = useAPIResponseToasts();

	const getTemplate = () => {
		if (allTemplates && currentTemplate) {
			return allTemplates.find((item) => item.title === currentTemplate.title);
		}
		return;
	};

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		onSubmit: async () => {
			const template = getTemplate();
			if (template) {
				setLoading(true);

				const response = await dispatch(deleteTemplate(template.id));
				handleNotifications(
					response?.meta.requestStatus,
					translate('deleteTemplateSuccess', language),
					translate('deleteTemplateError', language),
				);

				removeAllCriteriasHandler('calls');

				handleClose();
				setLoading(false);
			}
		},
	});

	return (
		<div>
			<ModalWindow isMWOpen={isOpen} handleMWClose={handleClose} text={translate('deleteTemplateText', language)}>
				<form onSubmit={formik.handleSubmit}>
					<div className={classes.mwButtonBox}>
						<LoadingButton
							loading={loading}
							type="submit"
							style={{ marginRight: '15px' }}
							variant="contained"
							color="error"
						>
							{translate('deleteButton', language)}
						</LoadingButton>
						<LoadingButton onClick={handleClose} variant="contained" color="secondary">
							{translate('cancelButtonMW', language)}
						</LoadingButton>
					</div>
				</form>
			</ModalWindow>
		</div>
	);
};

export default CreateNameTemplateModalWindow;
