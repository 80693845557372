import { FC, useEffect, useState } from 'react';
import { ContainedSelect } from 'components/common';
import cn from 'classnames';
import Tooltip, { TooltipPropsType } from 'components/common/Tooltip/Tooltip';
import { IconQuestion } from 'components/Icons/IconQuestion';

import cls from './styles.module.scss';

interface SelectWithHeaderProps {
	testId: string;
	name?: string;
	label: string;
	options?: Array<{ label: string; value: any }>;
	initialValue?: any;
	classNameLabel?: string;
	required?: boolean;
	tooltipTitle?: string;
	tooltipPlacement?: TooltipPropsType['placement'];
	tooltipTheme?: 'dark' | 'white';
	classNameWrapper?: string;
	placeholder?: string;
	value?: { label: string; value: string } | null;
	onSelectChange?: (selected: { label: string; value: string } | null) => void;
}

const SelectWithHeader: FC<SelectWithHeaderProps> = (props) => {
	const {
		testId,
		label,
		initialValue,
		options = [],
		name,
		classNameLabel,
		required,
		tooltipTitle,
		tooltipPlacement,
		tooltipTheme,
		classNameWrapper,
		placeholder,
		value,
		onSelectChange,
	} = props;

	// Определяем, является ли компонент контролируемым
	const isControlled = value !== undefined && onSelectChange !== undefined;

	// Локальное состояние для неконтролируемого режима
	const [selectedOption, setSelectedOption] = useState<{ label: string; value: string } | null>(
		isControlled ? value : initialValue ? options.find((option) => option.value === initialValue) || null : null,
	);

	useEffect(() => {
		if (isControlled) {
			// В контролируемом режиме обновляем состояние на основе пропа `value`
			setSelectedOption(value || null);
		} else {
			// В неконтролируемом режиме обновляем состояние на основе `initialValue`
			if (initialValue) {
				setSelectedOption(options.find((option) => option.value === initialValue) || null);
			} else {
				setSelectedOption(null);
			}
		}
	}, [value, initialValue, options, isControlled]);

	const handleSelectChange = (selected: { label: string; value: string } | null) => {
		if (!isControlled) {
			setSelectedOption(selected);
		}
		if (onSelectChange) {
			onSelectChange(selected);
		}
	};

	const currentValue = isControlled ? value : selectedOption;

	return (
		<div className={cn(cls.root, classNameWrapper)} data-testid={testId}>
			{label && (
				<label htmlFor={name} className={cn(cls.label, classNameLabel)}>
					{label}
					{required && <span className={cls.fieldRequired}>*</span>}
					{tooltipTitle && (
						<Tooltip
							title={tooltipTitle}
							placement={tooltipPlacement || 'top'}
							background={tooltipTheme || 'white'}
						>
							<IconQuestion />
						</Tooltip>
					)}
				</label>
			)}

			<ContainedSelect
				placeholder={placeholder || ''}
				width="100%"
				onSelectChange={handleSelectChange}
				value={currentValue}
				options={options}
			/>
		</div>
	);
};

export default SelectWithHeader;
