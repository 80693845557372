import React from 'react';

export const CaretIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.391 9.60024C11.1718 9.83693 10.8164 9.83693 10.5971 9.60024L7.9999 6.79651L5.40271 9.60024C5.18347 9.83693 4.82801 9.83693 4.60877 9.60024C4.38953 9.36355 4.38953 8.97987 4.60877 8.74319L7.60295 5.51082C7.8222 5.27414 8.1776 5.27414 8.39685 5.51082L11.391 8.74319C11.6103 8.97987 11.6103 9.36355 11.391 9.60024Z"
			fill={props.color ?? '#738094'}
		/>
	</svg>
);
