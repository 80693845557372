import React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import cn from 'classnames';
import { AttachmentsType, CallSttWordType } from 'store/calls/calls.types';
import useStyles from './styles.jss';
import { ColoredWords } from './types';
import { AttachmentIcon } from 'shared/ui';
import { getWordsIndices } from '../utils';

interface TextLineLayoutProps {
	time: string;
	speaker: string;
	callId: string;
	fragmentIndex: number;
	words: CallSttWordType[];
	highlightIndexes: number[];
	speakerNumber?: number;
	colorClassName?: string;
	timeClassName?: string;
	speakerClassName?: string;
	handleWordClick?: (i: number) => void;
	lineStartPadding?: string;

	coloredWords: ColoredWords;
	attachments?: AttachmentsType[];

	onMouseUpFunction?(event: React.MouseEvent<HTMLDivElement>): void;
}

const TextLineLayout: FC<TextLineLayoutProps> = ({
	time,
	speaker,
	fragmentIndex,
	words,
	callId,
	speakerNumber,
	highlightIndexes,
	handleWordClick,
	timeClassName,
	speakerClassName,
	colorClassName,
	lineStartPadding,
	coloredWords,
	attachments,
	onMouseUpFunction,
}) => {
	const classes = useStyles();
	const lineStartPaddingDefault = (index: number) => (index === 0 ? '15px' : '');

	const highlightIndexesSet = new Set(highlightIndexes.map((i) => i - 1));

	const decorateWords = useCallback(
		(fragmentIndex: number, indexFromCall: number): boolean | undefined => {
			if (coloredWords.fragmentIndex !== fragmentIndex || !coloredWords.matchData) return false;

			const indices = getWordsIndices(coloredWords.matchData, words, highlightIndexesSet);
			if (indices.has(indexFromCall)) return true;
		},
		[coloredWords.fragmentIndex, coloredWords.matchData],
	);

	const stylesForHover = { background: '#EFDBFF' };

	return (
		<div className={classes.diItem}>
			<Typography className={cn(classes.dateTypography, timeClassName)}>{time}</Typography>
			<Typography className={cn(classes.typographyTitle, speakerClassName, colorClassName)}>
				{speaker}
				{speakerNumber && <span className={classes.chatTitleNumber}>{speakerNumber}</span>}
			</Typography>
			<div className={classes.diBodyTextWrapper} onMouseUp={onMouseUpFunction}>
				<div className={classes.diBodyText}>
					{words.map((word, index) => {
						return (
							<div
								data-fragment-row-index={fragmentIndex}
								data-fragment-call-index={index}
								key={`${word.word}-${index}`}
								style={{
									userSelect: 'text',
									paddingLeft: lineStartPadding || lineStartPaddingDefault(index),
								}}
							>
								<span
									data-fragment-row-index={fragmentIndex}
									data-fragment-call-index={index}
									style={decorateWords(fragmentIndex, index) ? stylesForHover : undefined}
									onClick={() => {
										if (handleWordClick) handleWordClick(index);
									}}
									id={`${callId}-${fragmentIndex}-${index}`}
									className={`${classes.diFragment} ${
										highlightIndexes.find((hwi) => hwi === index + 1) && classes.tagMatch
									}`}
									key={word.conf}
								>
									{word.word}
									&nbsp;
								</span>
							</div>
						);
					})}
				</div>
				<div className={classes.attachments}>
					{attachments?.map((attachment, index) => (
						<AttachmentIcon
							key={`${attachment.name}-${index}`}
							width="16px"
							height="16px"
							className={classes.attachmentIcon}
							onClick={() => window.open(attachment.source, '_blank')}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default React.memo(TextLineLayout);
