import { FC } from 'react';
// import { ContainedSelect } from 'components/common';
import { translate } from 'localizations';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import ContainedSelect from '../../../components/common/Selects/ContainedSelect/ContainedSelect';
import { generateSortArray } from 'shared/lib/generateSortArray';

type SortOption = {
	value: string;
	sort_desc: boolean;
	label: string;
};

interface SortProps {
	language: string;
	page?: string;
	currentSort: {
		sort: string;
		sort_desc: boolean;
	};
	onSortChange: (option: { value: string; sort_desc: boolean }) => void;
	options?: SortOption[];
}

const SortComponent: FC<SortProps> = ({ page, currentSort, onSortChange, options, language }) => {
	const sortArray = generateSortArray(language, page || '');

	return (
		<ContainedSelect
			width="100%"
			onSelectChange={onSortChange}
			options={options || sortArray}
			value={sortArray.find(
				(sortItem) => sortItem.value === currentSort.sort && sortItem.sort_desc === currentSort.sort_desc,
			)}
		/>
	);
};

export default SortComponent;
