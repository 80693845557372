import { ContainedSelect } from 'components/common';
import React, { FC } from 'react';
import { FormikErrors } from 'formik';
import { translate } from 'localizations';
import { LangType } from 'store/lang/lang.slice';
import TooltipQuestion from 'shared/ui/TooltipQuestion/TooltipQuestion';
import s from './styles.module.scss';
import { ContainedSelectPropsType } from 'components/common/Selects/ContainedSelect/ContainedSelect';

interface STTSelectProps {
	title: string | undefined;
	placeholder: string;
	isDisabled: boolean;
	onSelectionChange: (e: any) => void;
	options: { value: string; label: string }[];
	value: { value: string; label: string } | null;
	errors: FormikErrors<string> | undefined;
	showErrors?: boolean;
	language: LangType | string;
	tooltip?: string;
	testId?: string;
	menuPlacement?: ContainedSelectPropsType['menuPlacement'];
}

const STTSelect: FC<STTSelectProps> = ({
	isDisabled,
	onSelectionChange,
	options,
	value,
	errors,
	showErrors = true,
	title,
	placeholder,
	language,
	tooltip,
	testId,
	menuPlacement,
}) => (
	<div className={s.selectRequired}>
		{title && (
			<div className={s.titleFlex}>
				<p className={s.title}>
					{title}
					{' *'}
				</p>

				{tooltip && <TooltipQuestion tooltipTitle={tooltip || ''} placement="right" background="dark" />}
			</div>
		)}

		<ContainedSelect
			height="38px"
			width="100%"
			justify="flex-end"
			margin="0 10px 0 0"
			testId={testId}
			placeholder={placeholder}
			disabled={isDisabled}
			onSelectChange={(e) => {
				if (e) onSelectionChange(e);
			}}
			menuPlacement={menuPlacement || 'bottom'}
			options={options}
			value={value}
			isSearchable
		/>

		{showErrors && errors ? <div className={s.requiredTitle}>{translate('required', language)}</div> : null}
	</div>
);

export default STTSelect;
