import { FC, useState } from 'react';
import { translate } from 'localizations';
import { PlusIcon } from 'components/Icons/PlusIcon';
import variables from 'styles/variables.scss';
import { UniversalButton } from 'shared/ui';
import GPTCommentForm from './GPTCommentForm';
import { createBrowserHistory } from 'history';
import { useAppSelector } from 'hooks/redux';

import s from './styles.module.scss';

interface PostGPTCommentProps {
	callId: string;
	lang: string;
}

const PostGPTComment: FC<PostGPTCommentProps> = ({ callId, lang }) => {
	const history = createBrowserHistory();
	const pathArray = history.location.pathname.split('/');

	const isAuth = useAppSelector((state) => state.user.isAuth);
	const [showForm, setShowForm] = useState(false);

	const isSingleCallWithoutAuth = pathArray.includes('call') && !isAuth;

	return (
		<div>
			{!isSingleCallWithoutAuth &&
				(showForm ? (
					<GPTCommentForm callId={callId} handleCloseForm={() => setShowForm(false)} lang={lang} />
				) : (
					<UniversalButton
						className={s.addButton}
						buttonStyle="plain"
						text={translate('addComment', lang)}
						icon={<PlusIcon fill={variables.purple_10} />}
						handleClick={() => setShowForm(!showForm)}
					/>
				))}
		</div>
	);
};

export default PostGPTComment;
