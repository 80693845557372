/**
 * вычисление процентов
 */

export const getPercents = (points: number | null, maxPoints: number | null): number | null => {
	if (points !== null && maxPoints !== null) {
		if (maxPoints === 0) return 100;
		return Math.round((points / maxPoints) * 100);
	}
	return null;
};

export const getPercentsSimple = (points: number | null) => {
	if (points === null) return null;
	return Math.round(points * 100);
};
export const getPointsColor = (scorePercent: number): string => {
	return scorePercent > 30 ? (scorePercent < 70 ? 'yellow' : 'green') : 'red';
};

export const getRoundedPercent = (score: number) => {
	return Math.round(score * 100) / 100;
};

export const formatToFinancial = (amount: number): string => {
	return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
