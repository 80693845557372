import React, { FC } from 'react';
import { CallSttFragmentType } from 'store/calls/calls.types';
import { timeConverter } from 'utils/convertDateAndTime/dateTimeFormating';
import useStyles from './styles.jss';
import { DialogItemType } from './types';
import TextLineLayout from './TextLineLayout';

const DialogItem: FC<DialogItemType> = ({
	callId,
	prevFragment,
	fragmentIndex,
	phrase,
	audioPlayerRef,
	coloredWords,
	onMouseUpFunction,
}) => {
	const classes = useStyles();
	const condition = phrase.direction === 'client';
	const time = timeConverter(phrase.begin, false);

	const prevChatFragment = prevFragment as CallSttFragmentType;

	const speakerWithoutNumber =
		prevChatFragment && (prevChatFragment.speaker_name !== phrase.speaker_name ? phrase.speaker_name : '');

	const speaker = typeof phrase?.speaker_num === 'number' ? phrase.speaker_name : speakerWithoutNumber;

	const speakerNumber = typeof phrase?.speaker_num === 'number' ? phrase?.speaker_num + 1 : undefined;

	const speakerNameStyles = condition ? classes.titleCustomer : classes.titleEmployee;

	return (
		<TextLineLayout
			time={time}
			speaker={speaker || ''}
			speakerNumber={speakerNumber}
			callId={callId}
			fragmentIndex={fragmentIndex}
			words={phrase.words}
			highlightIndexes={phrase.highlight_words_indexes}
			handleWordClick={(i: number) => {
				audioPlayerRef.current.audioEl.current.play();
				audioPlayerRef.current.audioEl.current.currentTime = phrase.words[i].begin / 1000 + 0.01;
			}}
			speakerClassName={classes.chatTitle}
			timeClassName={classes.chatTime}
			colorClassName={speakerNameStyles}
			lineStartPadding="0"
			coloredWords={coloredWords}
			onMouseUpFunction={onMouseUpFunction}
		/>
	);
};

export default React.memo(DialogItem);
