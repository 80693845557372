import { FC, SVGProps } from 'react';

interface ErrorCircleIconProps extends SVGProps<SVGSVGElement> {}

const ErrorCircleIcon: FC<ErrorCircleIconProps> = ({ width = '10', height = '10', ...props }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clip-path="url(#clip0_10795_82315)">
				<path
					d="M5 0.625C2.58398 0.625 0.625 2.58398 0.625 5C0.625 7.41602 2.58398 9.375 5 9.375C7.41602 9.375 9.375 7.41602 9.375 5C9.375 2.58398 7.41602 0.625 5 0.625ZM4.6875 2.89062C4.6875 2.84766 4.72266 2.8125 4.76562 2.8125H5.23438C5.27734 2.8125 5.3125 2.84766 5.3125 2.89062V5.54688C5.3125 5.58984 5.27734 5.625 5.23438 5.625H4.76562C4.72266 5.625 4.6875 5.58984 4.6875 5.54688V2.89062ZM5 7.1875C4.87734 7.185 4.76054 7.13451 4.67468 7.04688C4.58881 6.95924 4.54072 6.84144 4.54072 6.71875C4.54072 6.59606 4.58881 6.47826 4.67468 6.39062C4.76054 6.30299 4.87734 6.2525 5 6.25C5.12266 6.2525 5.23946 6.30299 5.32532 6.39062C5.41119 6.47826 5.45928 6.59606 5.45928 6.71875C5.45928 6.84144 5.41119 6.95924 5.32532 7.04688C5.23946 7.13451 5.12266 7.185 5 7.1875Z"
					fill="#FF4D4F"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10795_82315">
					<rect width="10" height="10" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ErrorCircleIcon;
