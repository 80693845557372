import { FC, SVGProps } from 'react';

interface TelegramIconProps extends SVGProps<SVGSVGElement> {}

const TelegramIcon: FC<TelegramIconProps> = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.3219 13.1922L12.3223 13.191L12.3339 13.162L14.3331 3.08264V3.0499C14.3331 2.79877 14.2403 2.57953 14.0384 2.44802C13.8617 2.33292 13.6585 2.32472 13.5161 2.33554C13.3653 2.34697 13.2233 2.38458 13.1249 2.41589C13.0743 2.432 13.032 2.44751 13.0017 2.45925C12.9865 2.46514 12.9741 2.47014 12.9652 2.47386L12.9564 2.47757L1.80985 6.8502L1.80679 6.85126C1.80077 6.85346 1.79281 6.85646 1.78319 6.8602C1.76402 6.86766 1.73793 6.87833 1.70727 6.89226C1.64709 6.91953 1.56393 6.9616 1.47959 7.01933C1.33636 7.11746 1.06361 7.34966 1.10967 7.7178C1.14781 8.02273 1.35802 8.2164 1.49973 8.31666C1.57569 8.37046 1.64837 8.4092 1.70159 8.43453C1.72855 8.4474 1.75146 8.4572 1.76852 8.46413C1.77707 8.4676 1.78422 8.4704 1.78975 8.47253L1.79684 8.47513L1.80136 8.47686L3.75153 9.1334C3.74495 9.25573 3.75708 9.38046 3.78953 9.5036L4.76639 13.2092C4.88221 13.6485 5.27969 13.9546 5.73407 13.9543C6.14136 13.954 6.50258 13.7076 6.65603 13.34L8.18113 11.7093L10.8005 13.7174L10.8377 13.7337C11.0757 13.8375 11.2981 13.8704 11.5013 13.8427C11.7043 13.8149 11.8656 13.7298 11.9868 13.6329C12.1061 13.5375 12.1877 13.4298 12.2391 13.3487C12.2653 13.3075 12.2847 13.2713 12.2982 13.2441C12.305 13.2304 12.3103 13.2189 12.3143 13.2098L12.3194 13.1982L12.3212 13.1939L12.3219 13.1922ZM4.75646 9.24866C4.73426 9.16446 4.76911 9.07553 4.84264 9.02886L11.4573 4.82824C11.4573 4.82824 11.8464 4.59204 11.8325 4.82824C11.8325 4.82824 11.9019 4.86995 11.6934 5.06444C11.4957 5.24916 6.979 9.60986 6.52195 10.0511C6.49557 10.0766 6.47982 10.1058 6.47052 10.1413L5.73332 12.9543L4.75646 9.24866Z"
			fill="#722ED1"
		/>
	</svg>
);

export default TelegramIcon;
