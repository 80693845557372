import { skipToken } from '@reduxjs/toolkit/dist/query';
import { manualTaggingAPI } from 'services/manualTaggingService';

export const useGetTagDetailedInfo = (callId: string, tagId: string | null) => {
	const {
		refetch: refetchTagDetailedInfo,
		data: tagDetailedInfo,
		isLoading: isLoadingTagDetailedInfo,
		error: isErrorTagDetailedInfo,
	} = manualTaggingAPI.useGetTagDetailedInfoQuery(callId && tagId ? { callId, tagId } : skipToken);

	return {
		data: { tagDetailedInfo, isLoadingTagDetailedInfo, isErrorTagDetailedInfo },
		methods: { refetchTagDetailedInfo },
	};
};
