import { TooltipForMenu } from 'components/common';
import { FC, ReactNode } from 'react';
import { MenuTag } from 'shared/ui/NewTagComponent/type';
import { translate } from 'localizations';
import s from './style.module.scss';

interface TagMenuProps {
	isOpen: boolean;
	handleTooltipClose: () => void;
	children: ReactNode;
	menu: MenuTag[];
	isLoading?: boolean;
	language: string;
}

const TagMenu: FC<TagMenuProps> = ({ isOpen, handleTooltipClose, menu, isLoading, language, children }) => {
	const menuItemsWithHandler = new Set(['delete', 'linkToRule', 'linkToDict', 'restore']);
	return (
		<TooltipForMenu
			open={isOpen}
			popperOffset={[0, -8]}
			handleTooltipClose={handleTooltipClose}
			title={
				<>
					{isLoading ? (
						<div className={s.tooltipMenuOne}> {translate('downloading', language)} ...</div>
					) : (
						menu?.map((menuItem) => {
							return (
								<>
									{!menuItem.hide ? (
										<div
											className={
												menuItem?.lineBefore
													? s.tooltipMenuWithLine
													: menu.length > 1
													? s.tooltipMenu
													: s.tooltipMenuOne
											}
											key={menuItem.id}
										>
											<div
												onClick={
													menuItemsWithHandler.has(menuItem.id)
														? () => {
																menuItem?.onClick();
																handleTooltipClose();
														  }
														: undefined
												}
												style={menuItem.id === 'delete' ? { color: 'red' } : {}}
											>
												{menuItem?.subTitle && (
													<div className={s.subTitle}>{menuItem?.subTitle}</div>
												)}
												<div className={s.title}>{menuItem.title}</div>
											</div>
										</div>
									) : null}
								</>
							);
						})
					)}
				</>
			}
			placement="bottom"
			isInteractive={true}
		>
			{children}
		</TooltipForMenu>
	);
};

export default TagMenu;
