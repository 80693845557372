import { FC, useState } from 'react';
import { NewTagComponent } from 'shared/ui';
import { CallHiddenTagType } from 'store/calls/calls.types';
import { getHiddenTagTheme } from 'pages/Calls/CallBody/utils';
import s from '../styles.module.scss';

interface ModalContentProps {
	title: string;
	tags: CallHiddenTagType[];
	language: string;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null) => void;
	handleRestoreTag: (id: string | null) => Promise<void>;
	toggleModalManual: () => void;
}

const ModalContent: FC<ModalContentProps> = ({
	title,
	tags,
	language,
	clickedTagId,
	handleClickTagSettings,
	handleRestoreTag,
	toggleModalManual,
}) => {
	return (
		<>
			{tags.length ? (
				<div className={s.content}>
					<div className={s.title}>{title}</div>
					<div className={s.tags}>
						{tags.map((tag) => {
							return (
								<NewTagComponent
									settings
									id={tag.id}
									accessRights={true}
									tagGroup="restore"
									key={tag.name + tag.value}
									name={tag.name}
									value={tag.value ?? null}
									theme={getHiddenTagTheme(tag)}
									language={language}
									cursor="pointer"
									handleRestoreTag={handleRestoreTag}
									isActiveTag={clickedTagId === tag.id}
									handleClickTag={handleClickTagSettings}
									toggleModalManual={toggleModalManual}
								/>
							);
						})}
					</div>
				</div>
			) : null}
		</>
	);
};

export default ModalContent;
