import { useEffect } from "react";
import { useManualTaggingService } from "widgets/ManualTagging/hooks/useManualTaggingService";
import useMessages from "widgets/ManualTagging/hooks/useMessages";
import { useActionsFunctions } from "../../../../../hooks/useActionsFunctions";

const useManualModal = (callId: string | undefined) => {
  const { updateCall } = useActionsFunctions(null);
  const { data, methods } = useManualTaggingService(callId as string);
  const { 
    isErrorRestoreTag, 
    isLoadingRestoreTag, 
    isSuccessRestoreTag,
    hiddenTags,
    isLoadingHiddenTags,
    isErrorHiddenTags
   } = data;
  const { getHiddenTags, restoreHiddenTag, refetchHiddenTags } = methods;
  const { showSuccessRestoreTag, showErrorRestoreTag } = useMessages();

  const handleRestoreTag = async (id: string | null) => {
    await restoreHiddenTag({ callId, tagId: id });
  }

	// обновление звонка
	const getUpdatedCall = async () => {
		if (callId)	{ 
      await updateCall(callId);
    }
	};

  const handleRefetchTags = async () => {
    if (callId) await refetchHiddenTags();
  }
  
  useEffect(() => {
    if (isSuccessRestoreTag) {
      showSuccessRestoreTag();
      getUpdatedCall();
    }
  }, [isSuccessRestoreTag]);

  useEffect(() => {
    if (isErrorRestoreTag) {
      showErrorRestoreTag();
    }
  }, [isErrorRestoreTag]);

  return {
    data: {
      hiddenTags,
      isLoading: isLoadingHiddenTags,
    },
    methods: {
      handleRestoreTag,
      handleRefetchTags,
    },
  }
}

export default useManualModal;