import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'api/api.config';

export const billingAPI = createApi({
	reducerPath: 'billingAPI',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		getUserBillingInfo: build.query({
			query: () => ({
				url: `/billing/billing_info`,
			}),
		}),
		getUserCharges: build.query({
			query: () => ({
				url: `/billing/charges`,
			}),
		}),
		getUserPayments: build.query({
			query: () => ({
				url: `/billing/payments`,
			}),
		}),
		requestInvoice: build.mutation({
			query: (body) => ({
				url: '/billing/request_invoice',
				method: 'POST',
				body,
			}),
		}),
	}),
});
