import { TagTypeEnum } from "pages/Calls/types";
import { CallHiddenTagType } from "store/calls/calls.types";
import { translate } from "localizations";
import { TagContentType } from "./types";

export const getTags = (tags: CallHiddenTagType[], language: string) => {
  const content: TagContentType = {
    deals: {
      id: 'deals',
      title: translate('dealsTags', language),
      tags: []
    },
    calls: {
      id: 'calls',
      title: translate('right_tags', language),
      tags: []
    },
    fragments: {
      id: 'fragments',
      title: translate('fragments', language),
      tags: []
    },
    manual: {
      id: 'manual',
      title: translate('manualTags', language),
      tags: []
    },
  };

  tags.forEach((tag) => {
    if (tag.tagType === TagTypeEnum.manual) {
      content.manual.tags.push(tag);
    } else if (tag.matchData) {
      content.fragments.tags.push(tag);
    } else if (tag.deal) {
      content.deals.tags.push(tag);
    } else {
      content.calls.tags.push(tag);
    }
  });
  return content;
};