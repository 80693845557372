import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'api/api.config';
import { getTagDetailedConvertedInfo } from './utils/manulTags';
import { TagDetailedInfo } from 'shared/ui/NewTagComponent/type';
import { ExtendedCallTagType } from 'store/calls/calls.types';

export const manualTaggingAPI = createApi({
	reducerPath: 'manualTaggingAPI',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		// получение ручных тегов
		getManualTags: build.query({
			query: () => ({
				url: `/tag_names?tag_group=manual`,
			}),
		}),
		// отдает список удаленных тегов, включая ID
		getHiddenTags: build.query({
			query: (callId) => ({
				url: `/call/${callId}/hidden_tags`,
			}),
		}),
		// получение детальной инфы о теге
		getTagDetailedInfo: build.query({
			query: ({ callId, tagId }) => ({
				url: `/call/${callId}/tag/${tagId}`,
			}),
			transformResponse: (response: ExtendedCallTagType): TagDetailedInfo =>
				getTagDetailedConvertedInfo(response),
		}),
		// создание тега
		addManualTag: build.mutation({
			query: ({ callId, params }) => ({
				url: `/call/${callId}/tag`,
				method: 'POST',
				body: params,
			}),
		}),
		// удаление тега
		deleteManualTag: build.mutation({
			query: ({ callId, tagId }) => ({
				url: `/call/${callId}/tag/${tagId}`,
				method: 'DELETE',
			}),
		}),
		// восстановление удаленного тега
		restoreHiddenTag: build.mutation({
			query: ({ callId, tagId }) => ({
				url: `/call/${callId}/hidden_tag/${tagId}`,
				method: 'DELETE',
			}),
		}),
	}),
});
