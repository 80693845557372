import { FC, useState } from 'react';
import classNames from 'classnames';
import { GPTCallComment as GPTComment } from 'store/calls/calls.types';
import CaretDown from 'shared/ui/icons/general/CaretDown/CaretDown';
import UniversalButton from 'shared/ui/Buttons/UniversalButton/UniversalButton';
import { translate } from 'localizations';
import GPTCallComment from './components/GPTCallComment/GPTCallComment';
import PostGPTComment from './components/PostGPTComment/PostGPTComment';
import { visibleCommentsLimit } from './constants';

import s from './styles.module.scss';

interface GPTCallCommentsProps {
	callId: string;
	expanded: boolean;
	comments: GPTComment[];
	className?: string;
	lang: string;
}

const GPTCallComments: FC<GPTCallCommentsProps> = ({ callId, expanded, comments, className, lang }) => {
	const shownComments = expanded ? comments : comments.slice(0, visibleCommentsLimit);

	const [visibleComments, setVisibleComments] = useState<GPTComment[]>(shownComments);
	const [showAll, setShowAll] = useState(false);
	const [iconStyles, setIconStyles] = useState<Record<string, string>>({ transform: 'rotate(180deg)' });

	const initialMoreBtnText = `${translate('showMore', lang)} ${comments?.length - 1}`;
	const hideBtnText = translate('tagHide', lang);
	const [moreBtnText, setMoreBtnText] = useState(initialMoreBtnText);

	const toggleVisibleComments = () => {
		if (!showAll) {
			setVisibleComments(comments);
			setIconStyles({ transform: 'rotate(0deg)' });
			setMoreBtnText(hideBtnText);
		} else {
			setVisibleComments(shownComments);
			setIconStyles({ transform: 'rotate(180deg)' });
			setMoreBtnText(initialMoreBtnText);
		}
		setShowAll(!showAll);
	};

	return (
		<div
			className={classNames(s.root, className, {
				[s.withAllComments]: expanded,
			})}
		>
			{expanded && visibleComments?.length > 0 && (
				<h3 className={s.title}>{translate('commentsForCall', lang)}</h3>
			)}
			{visibleComments.map((item, index) => (
				<GPTCallComment
					key={item.id}
					index={index}
					className={expanded ? s.withAllCommentsItem : ''}
					withOptions={expanded}
					callId={callId}
					comments={comments}
					lang={lang}
					{...item}
				/>
			))}
			{!expanded && comments?.length > visibleCommentsLimit && (
				<UniversalButton
					className={s.moreButton}
					icon={<CaretDown style={iconStyles} />}
					text={moreBtnText}
					handleClick={toggleVisibleComments}
				/>
			)}
			{expanded && <PostGPTComment callId={callId} lang={lang} />}
		</div>
	);
};

export default GPTCallComments;
