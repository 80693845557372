import React, { FC } from 'react';
import styles from './styles.module.scss';
import TooltipQuestion from '../TooltipQuestion/TooltipQuestion';
import { TooltipPropsType } from 'components/common/Tooltip/Tooltip';

interface SettingsTitleProps {
	className?: string;
	tooltipTitle?: string;
	tooltipPlacement?: TooltipPropsType['placement'];
}

const CardContainerTitle: FC<SettingsTitleProps> = ({ children, className, tooltipTitle, tooltipPlacement }) => (
	<h3 className={`${styles.title} ${className}`}>
		{children}
		{tooltipTitle && (
			<span style={{ marginLeft: '16px', height: '20px' }}>
				<TooltipQuestion tooltipTitle={tooltipTitle} placement="right" />
			</span>
		)}
	</h3>
);

export default CardContainerTitle;
