import { FC, SVGProps } from 'react';

interface CrossIconProps extends SVGProps<SVGSVGElement> {
	color?: string;
}

const CrossIcon: FC<CrossIconProps> = ({ color = '#000000', width = 20, height = 20, ...props }) => (
	<svg viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill={color} width={width} height={height} {...props}>
		<path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.03-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
	</svg>
);

export default CrossIcon;
