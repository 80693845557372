import { translate } from "localizations";
import { FC, useEffect } from "react";
import { LangType } from "store/lang/lang.slice";
import useManualModal from "./hooks/useManualModal";
import { ModalWindow, UniversalButton } from "shared/ui";
import ManualModalInner from "./ManualModalInner";
import { CallHiddenTagType } from "store/calls/calls.types";
import { useToggleOpen } from "hooks";
import s from './styles.module.scss';

interface ManulModalProps {
  language: LangType | string;
  callId?: string;
  isOpenModalManual: boolean;
  toggleModalManual: () => void;
}

const ManualModal:FC<ManulModalProps> = ({ language, callId, isOpenModalManual, toggleModalManual }) => {
  const { data, methods } = useManualModal(callId);
  const { hiddenTags, isLoading } = data;
  const { handleRestoreTag, handleRefetchTags } = methods;
  
  const manualTags = translate('resetManual', language);
	const manualTagsRestore = translate('hiddenTagsResetMulti', language);
	const { toggle: toggleModalManualQue, isShowing: isOpenModalManualQue } = useToggleOpen();
  const styles = { display: 'flex', justifyContent: 'flex-end' };
  
  const handleRestoreTags = () => {
		const restoredTags = Promise.all(
			(hiddenTags as CallHiddenTagType[]).map(async (tag) => {
				let parameter = await handleRestoreTag(tag.id);
				return parameter;
			}),
		).then(() => {
			toggleModalManualQue();
			toggleModalManual();
		});
	};

  useEffect(() => {
    if (isOpenModalManual) {
      handleRefetchTags();
    }
  },[isOpenModalManual]);
	
  return (
    <>
      <ModalWindow
        isMWOpen={isOpenModalManual}
        handleMWClose={toggleModalManual}
        text={manualTags}
        width="600px"
        classNameTitleText={s.titleModal}
        blockFotter={
          <div style={styles}>
            {hiddenTags && Boolean(hiddenTags.length) && (
              <UniversalButton
                buttonStyle="ghost"
                text={translate('restoreButtonAll', language)}
                handleClick={toggleModalManualQue}
                testId={'restore_tags_ok'}
              />
            )}
          </div>
        }
      >
        <ManualModalInner 
          callId={callId} 
          language={language} 
          toggleModalManual={toggleModalManual}
          hiddenTags={hiddenTags}
          isLoading={isLoading}
          handleRestoreTag={handleRestoreTag}
          />
      </ModalWindow>

      <ModalWindow
        isMWOpen={isOpenModalManualQue}
        handleMWClose={toggleModalManualQue}
        text={manualTagsRestore}
        classNameTitleText={s.titleModal}
        width="600px"
        blockFotter={
          <div style={styles}>
            <UniversalButton
              buttonStyle="ghost"
              text={translate('cancelButton', language)}
              handleClick={toggleModalManualQue}
              testId={'restore_tag_cancel'}
            />
            <UniversalButton
              buttonStyle="filled"
              text={translate('restoreButton', language)}
              handleClick={handleRestoreTags}
              testId={'restore_tag_ok'}
            />
          </div>
        }
      >
        <div className={s.contentModal}>{translate('hiddenTagsResetQueMulti', language)}</div>
      </ModalWindow>
    </>
  )
}

export default ManualModal;