import { FC, memo, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { Skeleton } from '@mui/material';
import ArrowIcon from 'components/Icons/ArrowIcon';
import { useStyles } from '../../Call.jss';

interface AccordionSummaryComponentProps extends AccordionSummaryProps {
	isAuth: boolean | 'loading';
	isLoading: boolean;
	onClick: (e: MouseEvent<HTMLElement>) => void;
	showOpenLink: boolean;
	getOpenLinkButton: () => JSX.Element | null;
	solo?: boolean;
	language: string;
	classes: ReturnType<typeof useStyles>;
}

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
	// backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	width: '100%',
	alignItems: 'baseline !important',
	'& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
		margin: '9px !important',
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)',
	},
	'& .Mui-expanded': {
		background: '#F8FAFC !important',
		// alignItems: 'center !important',
		marginTop: '16px',
	},
}));

const AccordionSummaryComponent: FC<AccordionSummaryComponentProps> = ({
	isAuth,
	isLoading,
	onClick,
	showOpenLink,
	getOpenLinkButton,
	solo = false,
	language,
	classes,
	...accordionProps
}) => {
	return (
		<div style={{ display: 'flex', width: '100%', zIndex: 150, cursor: isLoading ? 'wait' : 'default' }}>
			<StyledMuiAccordionSummary
				tabIndex={-1}
				onClick={onClick}
				expandIcon={
					!solo && (
						<div>
							{isAuth && !isLoading ? (
								<span onClick={onClick} className={classes.expandIcon} data-testid="call_expand">
									<ArrowIcon />
								</span>
							) : (
								<Skeleton width={32} height={52} variant="text" style={{ margin: '0 0 -18px 0' }} />
							)}
						</div>
					)
				}
				{...accordionProps}
			/>
			{showOpenLink && getOpenLinkButton()}
		</div>
	);
};

export default memo(AccordionSummaryComponent);
