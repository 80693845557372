import React from 'react';
import { useAppDispatch } from 'hooks/redux';
import { Skeleton } from '@mui/material';
import { searchSlice } from 'store';
import { DisableMask, CriteriasList, Tooltip as TooltipCustom } from 'components/common';
import { translate } from 'localizations';
import { AccessRights } from 'store/user/user.types';
import { CriteriaBlockPage, CriteriasType, TemplateType } from 'store/search/search.types';
import { Page } from 'components/common/Criterias/CriteriasList';

interface IOwnProps {
	blockTitle?: string;
	simpleListWithTitles: boolean;
	accessRights: AccessRights | null;
	language: string;
	activeCriterias: CriteriasType[];
	allCriterias: CriteriasType[];
	onTemplateSelectValueChange: (event: any) => void;
	currentTemplate?: TemplateType | null;
	allTemplates?: TemplateType[];
	block?: CriteriaBlockPage;
	page: Page;
	onClearTemplateClick?: () => void;
	loadingCriteriasList?: boolean;
	convertedTemplate: {
		value: any;
		label: string;
	}[];
}

const SearchFilters: React.FC<IOwnProps> = ({
	simpleListWithTitles,
	accessRights,
	language,
	allCriterias,
	activeCriterias,
	block,
	page,
	blockTitle,
	onTemplateSelectValueChange,
	convertedTemplate,
	currentTemplate,
	allTemplates,
	onClearTemplateClick,
	loadingCriteriasList = false,
}) => {
	const dispatch = useAppDispatch();

	return (
		<div>
			<div style={{ display: 'flex', width: '100%' }}>
				<div style={{ display: 'flex', alignItems: 'flex-start', minHeight: '50px', width: '100%' }}>
					<div style={{ width: '100%' }}>
						<DisableMask
							disable={!(accessRights && (accessRights.search || accessRights.admin))}
							tooltip={translate('noAvailableSearchFilters', language)}
						>
							{loadingCriteriasList ? (
								<>
									<Skeleton width={300} height={40} />
								</>
							) : (
								<CriteriasList
									simpleListWithTitles={simpleListWithTitles}
									page={page}
									allCriterias={allCriterias}
									activeCriterias={activeCriterias}
									block={block}
									onAddCondition={(data, pageName) => {
										dispatch(
											searchSlice.actions.setActiveCriteriaValues({
												page: pageName as CriteriaBlockPage,
												data,
											}),
										);
									}}
									onDeleteCondition={(data, pageName) =>
										dispatch(
											searchSlice.actions.removeComplexCriteria({
												page: pageName as CriteriaBlockPage,
												data,
											}),
										)
									}
									blockTitle={blockTitle}
									onTemplateSelectValueChange={onTemplateSelectValueChange}
									convertedTemplate={convertedTemplate}
									currentTemplate={currentTemplate}
									allTemplates={allTemplates}
									onClearTemplateClick={onClearTemplateClick}
								/>
							)}
						</DisableMask>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchFilters;
