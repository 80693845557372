import { IconQuestion } from 'components/Icons/IconQuestion';
import Tooltip, { TooltipPropsType } from 'components/common/Tooltip/Tooltip';
import { FC } from 'react';

interface TooltipQuestionProps {
	tooltipTitle: string;
	rootClassName?: string;
	background?: 'dark' | 'white';
	placement?: TooltipPropsType['placement'];
}

const TooltipQuestion: FC<TooltipQuestionProps> = ({ tooltipTitle, placement, background = 'dark', rootClassName }) => {
	return (
		<div className={rootClassName}>
			<Tooltip title={tooltipTitle} placement={placement} background={background}>
				<IconQuestion />
			</Tooltip>
		</div>
	);
};

export default TooltipQuestion;
