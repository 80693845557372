import { useCallback, useEffect } from 'react';
import { manualTaggingAPI } from 'services/manualTaggingService';
import { useActionsFunctions } from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/hooks/useActionsFunctions';
import useMessages from './useMessages';

const useManualTaggingActions = (callId: string | undefined) => {
	const [deleteManualTag, { isError: isErrorDeleteManual, isSuccess: isSuccessDeleteManual }] =
		manualTaggingAPI.useDeleteManualTagMutation();
	const { showSuccessMessageDelete, showErrorMessageDelete } = useMessages();
	const { updateCall } = useActionsFunctions(null);

	useEffect(() => {
		if (isSuccessDeleteManual) {
			showSuccessMessageDelete();
			getUpdatedCall();
		}
	}, [isSuccessDeleteManual]);

	useEffect(() => {
		if (isErrorDeleteManual) {
			showErrorMessageDelete();
		}
	}, [isErrorDeleteManual]);

	// обновление звонка
	const getUpdatedCall = async () => {
		if (callId) await updateCall(callId);
	};

	// удаление тега
	const handleDeleteTag = useCallback(
		async (id: string) => {
			await deleteManualTag({
				callId,
				tagId: id,
			});
		},
		[callId],
	);

	return {
		handleDeleteTag,
	};
};

export default useManualTaggingActions;
