/* eslint-disable indent */
import React from 'react';
import { Skeleton, Typography } from '@mui/material';
import { TagGroup } from 'widgets/ManualTagging/types';
import { translate } from '../../../../localizations';
import CallsFragments from '../CallsFragments/CallsFragments';
import CallsTags from '../CallsTags/CallsTags';
import { CallInfoType, CallTagType } from '../../../../store/calls/calls.types';
import { AccessRights } from '../../../../store/user/user.types';
import DealTags from '../DealTags/DealTags';
import s from './styles.module.scss';

export interface ISearchedWord {
	begin: number;
	fragment: string;
	key: string | null;
	text: string;
	fBegin?: number;
}

interface IOwnProps {
	setCurrentFragmentHandle: (value: CallTagType | ISearchedWord) => void;
	callInfo: CallInfoType | null;
	expanded: boolean;
	accessRights: AccessRights | null;
	language: string;
	// TODO: Igor - fix any types
	tagsAndFragmentsArray: {
		tags: any[];
		fragments: any[];
		searchedWords?: ISearchedWord[];
		dealTags: CallTagType[];
	};
	classes: any;
	solo: boolean | undefined;
	deleteTag: ((id: string) => Promise<void>) | undefined;
	tagGroup: TagGroup | null;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
}

const TagsBlock: React.FC<IOwnProps> = ({
	tagsAndFragmentsArray,
	callInfo,
	expanded,
	setCurrentFragmentHandle,
	accessRights,
	language,
	classes,
	solo,
	deleteTag,
	tagGroup,
	clickedTagId,
	handleClickTagSettings,
}) => (
	<div style={{ width: '100%', display: 'flex' }}>
		<div style={{ width: '100%' }}>
			{callInfo && (
				<div style={{ position: 'absolute', top: '-2px', display: 'flex' }}>
					{callInfo?.unparsedByQuota && (
						<Typography
							style={{
								color: '#9a0c21',
								fontWeight: '700',
								fontSize: '11px',
							}}
						>
							{translate('overQuota', language)}
						</Typography>
					)}
				</div>
			)}
			{/* DealTags */}
			{tagsAndFragmentsArray?.dealTags?.length > 0 &&
			tagsAndFragmentsArray.dealTags.some((tag) => tag.visible) ? (
				<div className={s.tagsBlock}>
					<div className={s.tagsTitle}>
						<Typography className={classes.lignthTextSmall}>{translate('dealTags', language)}</Typography>
					</div>
					<DealTags
						language={language}
						dealTags={tagsAndFragmentsArray?.dealTags}
						callId={callInfo?.id}
						hasCallInfo={Boolean(callInfo)}
						solo={solo}
						expanded={expanded}
						accessRights={accessRights?.manual_tagging}
						tagGroup={tagGroup}
						deleteTag={deleteTag}
						clickedTagId={clickedTagId}
						handleClickTagSettings={handleClickTagSettings}
					/>
				</div>
			) : null}

			{/* CallsTags */}
			{!solo && accessRights?.tags && tagsAndFragmentsArray.tags.some((tag) => tag.visible) ? (
				<div className={s.tagsBlock}>
					<div className={s.tagsTitle}>
						{tagsAndFragmentsArray?.tags?.length > 0 ? (
							<Typography className={classes.lignthTextSmall}>
								{translate('callsTags', language)}
							</Typography>
						) : (
							<Skeleton width={85} height={20} variant="text" />
						)}
					</div>
					<CallsTags
						accessRights={accessRights?.manual_tagging}
						language={language}
						tags={tagsAndFragmentsArray?.tags}
						callId={callInfo?.id}
						hasCallInfo={Boolean(callInfo)}
						solo={solo}
						expanded={expanded}
						tagGroup={tagGroup}
						deleteTag={deleteTag}
						clickedTagId={clickedTagId}
						handleClickTagSettings={handleClickTagSettings}
					/>
				</div>
			) : (
				<div className={s.tagsBlock}>
					<div className={s.tagsTitle}>
						{solo && tagsAndFragmentsArray?.tags?.length > 0 ? (
							<Typography className={classes.lignthTextSmall}>
								{translate('callsTags', language)}
							</Typography>
						) : (
							<Skeleton width={85} height={20} variant="text" />
						)}
					</div>
					<CallsTags
						accessRights={accessRights?.manual_tagging}
						language={language}
						tags={tagsAndFragmentsArray?.tags}
						callId={callInfo?.id}
						hasCallInfo={Boolean(callInfo)}
						solo={solo}
						tagGroup={tagGroup}
						expanded={expanded}
						deleteTag={deleteTag}
						clickedTagId={clickedTagId}
						handleClickTagSettings={handleClickTagSettings}
					/>
				</div>
			)}

			{/* Fragments */}
			{!solo && tagsAndFragmentsArray.fragments.some((tag) => tag.tag.visible) ? (
				<div className={s.tagsBlock}>
					<div className={s.tagsTitle}>
						<Typography className={classes.lignthTextSmall}>{translate('fragments', language)}</Typography>
					</div>
					<CallsFragments
						language={language}
						accessRights={accessRights?.manual_tagging}
						expanded={expanded}
						setCurrentFragmentHandle={setCurrentFragmentHandle}
						tagsAndFragmentsArray={tagsAndFragmentsArray}
						callId={callInfo?.id}
						solo={solo}
						deleteTag={deleteTag}
						tagGroup={tagGroup}
						clickedTagId={clickedTagId}
						handleClickTagSettings={handleClickTagSettings}
					/>
				</div>
			) : (
				<div className={s.tagsBlock}>
					<div className={s.tagsTitle}>
						{solo && (
							<Typography className={classes.lignthTextSmall}>
								{translate('fragments', language)}
							</Typography>
						)}
					</div>
					<CallsFragments
						language={language}
						accessRights={accessRights?.manual_tagging}
						expanded={expanded}
						setCurrentFragmentHandle={setCurrentFragmentHandle}
						tagsAndFragmentsArray={tagsAndFragmentsArray}
						callId={callInfo?.id}
						solo={solo}
						tagGroup={tagGroup}
						deleteTag={deleteTag}
						clickedTagId={clickedTagId}
						handleClickTagSettings={handleClickTagSettings}
					/>
				</div>
			)}
		</div>
	</div>
);

export default React.memo(TagsBlock);
