import { FC, SVGProps } from 'react';

interface EnvelopeIconProps extends SVGProps<SVGSVGElement> {}

const EnvelopeIcon: FC<EnvelopeIconProps> = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M14.5 2.5H1.5C1.22344 2.5 1 2.72344 1 3V13C1 13.2766 1.22344 13.5 1.5 13.5H14.5C14.7766 13.5 15 13.2766 15 13V3C15 2.72344 14.7766 2.5 14.5 2.5ZM13.875 4.23125V12.375H2.125V4.23125L1.69375 3.89531L2.30781 3.10625L2.97656 3.62656H13.025L13.6938 3.10625L14.3078 3.89531L13.875 4.23125V4.23125ZM13.025 3.625L8 7.53125L2.975 3.625L2.30625 3.10469L1.69219 3.89375L2.12344 4.22969L7.46094 8.37969C7.61444 8.49894 7.80328 8.56367 7.99766 8.56367C8.19203 8.56367 8.38088 8.49894 8.53438 8.37969L13.875 4.23125L14.3062 3.89531L13.6922 3.10625L13.025 3.625Z"
			fill="#722ED1"
		/>
	</svg>
);

export default EnvelopeIcon;
