import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { CallInfoType, CallInitialStateType, CallType } from 'store/calls/calls.types';
import { AccessRights, UserType } from 'store/user/user.types';
import { TCallCheckList } from 'store/checkLists/namespaces/responses';
import { BlockBox } from 'components/common';
import { TUpdateCurrentCallCheckListAnswerById } from 'store/checkLists/namespaces/payloads';

import CallBodyButtons from '../CallBodyButtons/CallBodyButtons';
import CallsCheckLists from '../CallsCheckLists/CallsCheckLists';
import './styles.css';
import ManualTagging from 'widgets/ManualTagging/ui/ManualTagging';
import { TagTypeEnum } from 'pages/Calls/types';
import { TagGroup } from 'widgets/ManualTagging/types';

interface IOwnProps {
	language: string;
	paramsDivRef: React.LegacyRef<HTMLDivElement>;
	callInfo: CallInfoType;
	currentCall: CallInitialStateType['currentCall'];
	accessRights: AccessRights | null;
	isCheckListsLoading: boolean;
	checkLists: TCallCheckList[];
	isAuth: boolean | 'loading';
	classes: Record<string, string>;
	userInfo: UserType | null;
	currentPage: number | undefined;
	showParams?: boolean;
	isCallLoading: boolean;
	updateCheckList(target: TUpdateCurrentCallCheckListAnswerById): void;
	tagGroup: TagGroup | null;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
}

const CallBodyCallParams: React.FC<IOwnProps> = ({
	language,
	paramsDivRef,
	classes,
	callInfo,
	currentCall,
	accessRights,
	checkLists,
	isCheckListsLoading,
	isAuth,
	updateCheckList,
	userInfo,
	currentPage,
	isCallLoading,
	tagGroup,
	clickedTagId,
	handleClickTagSettings,
	showParams = true,
}) => (
	<div ref={paramsDivRef} className={classes.callBodyCallParams}>
		{showParams && (
			<CallBodyButtons
				isAuth={isAuth}
				language={language}
				callInfo={callInfo}
				currentCall={currentCall}
				userInfo={userInfo}
				currentPage={currentPage}
			/>
		)}

		{/* ручное тегирование */}
		{accessRights?.manual_tagging && (
			<ManualTagging
				callId={callInfo.id}
				language={language}
				tags={callInfo.tags.filter((tag) => tag.tagType === TagTypeEnum.manual)}
				isCallLoading={isCallLoading}
				accessRights={accessRights?.manual_tagging}
				tagGroup={tagGroup}
				clickedTagId={clickedTagId}
				handleClickTagSettings={handleClickTagSettings}
			/>
		)}

		{accessRights?.checklist_view &&
			(isCheckListsLoading ? (
				<BlockBox margin="16px 0 0" height="auto">
					<div className="CallBodyCallParamsLoader">
						<CircularProgress />
					</div>
				</BlockBox>
			) : (
				<CallsCheckLists
					canUpdateChecklists={accessRights && accessRights.checklist_update}
					currentCall={currentCall as CallType}
					updateCheckList={updateCheckList}
					checkLists={checkLists}
					language={language}
					callCheckListDisabled={!accessRights?.checklist_update}
				/>
			))}
	</div>
);

export default React.memo(CallBodyCallParams);
