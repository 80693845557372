import { FC, useEffect, useState } from "react";
import { CircularPreloader } from "components/common";
import { TagContentItemType, TagContentType } from "./types";
import { getTags } from "./utils";
import { translate } from "localizations";
import ModalContent from "./components/ModalContent";
import s from './styles.module.scss';
import { CallHiddenTagType } from "store/calls/calls.types";

interface ManualModalInnerProps {
  callId: string | undefined;
  language: string;
  toggleModalManual: () => void;
  isLoading: boolean;
  hiddenTags: CallHiddenTagType[];
  handleRestoreTag:  (id: string | null) => Promise<void>;
}

const ManualModalInner:FC<ManualModalInnerProps> = ({ callId, language, toggleModalManual, isLoading, hiddenTags, handleRestoreTag }) => {
  const [content, setContent] = useState<TagContentType | null>(null);
  const [clickedTagId, setClickedTagId] = useState<string | null>(null);
	const handleClickTagSettings = (id: string | null) => {
		setClickedTagId(id);
	};

  useEffect(() => {
    if (hiddenTags && hiddenTags.length) setContent(getTags(hiddenTags, language));
  }, [hiddenTags]);
  
  return (
    <div className={s.hiddenTags}>
      {
        isLoading ? 
          <CircularPreloader />
        :
        <>
          {hiddenTags.length && content ? 
            <>
              {Object.keys(content).map((contentItem) => {
                return ( 
                  <ModalContent 
                    key={content[contentItem as TagContentItemType].id}
                    title={content[contentItem as TagContentItemType].title}
                    tags={content[contentItem as TagContentItemType].tags}
                    language={language} 
                    clickedTagId={clickedTagId}
                    handleClickTagSettings={handleClickTagSettings}
                    handleRestoreTag={handleRestoreTag}
                    toggleModalManual={toggleModalManual}
                  />
                )
              })}
            </>
          :
          <>{translate('noHiddenTags', language)}</>
        }
        </>
      }
    </div>
  )
}

export default ManualModalInner;