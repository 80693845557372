import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import { translate } from 'localizations';
import { DisableMask, SaveCriterias } from 'components/common';
import AddFilterSelect from 'components/Search/AddFilterSelect/AddFilterSelect';
import { AccessRights } from 'store/user/user.types';
import { CriteriasType, TemplateType } from 'store/search/search.types';
import { useCallSearchStyles } from '../../Search.jss';

interface IOwnProps {
	isLoading: boolean;
	searchRequest(): void;
	language: string;
	accessRights: AccessRights | null;
	removeAllCriteriasHandler(): void;
	handleCreateNewTemplateButtonClick(): void;
	setCriterias(criterias: CriteriasType[]): void;
	removeCriterias(criterias: CriteriasType[]): void;
	setCriteria(criterias: CriteriasType): void;
	removeCriteria(criterias: CriteriasType): void;
	currentTemplate?: TemplateType | null;
	activeCriterias: CriteriasType[];
	// TODO: Igor - rename field
	op: any[];
	handleSelectChange: (event: { value: string }) => void;
}

const SearchControls: React.FC<IOwnProps> = ({
	searchRequest,
	language,
	isLoading,
	accessRights,
	setCriterias,
	removeCriterias,
	setCriteria,
	removeCriteria,
	op,
	activeCriterias,
	currentTemplate,
	handleSelectChange,
	removeAllCriteriasHandler,
	handleCreateNewTemplateButtonClick,
}) => {
	const classes = useCallSearchStyles();

	return (
		<div className={classes.btnsFlex}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<LoadingButton
					data-testid="calls_btns_find"
					className={classes.searchSearchButton}
					color="primary"
					variant="contained"
					loading={isLoading}
					onClick={searchRequest}
				>
					{translate('findCalls', language)}
				</LoadingButton>

				<DisableMask
					disable={!(accessRights && (accessRights.search || accessRights.admin))}
					tooltip={translate('noAvailableSearchFilters', language)}
				>
					<div style={{ margin: '0 22px 0 22px', whiteSpace: 'nowrap' }}>
						<AddFilterSelect
							setCriterias={setCriterias}
							removeCriterias={removeCriterias}
							setCriteria={setCriteria}
							removeCriteria={removeCriteria}
							name="moreSelect"
							value={null}
							options={op}
							customControl={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Typography
										style={{
											color: '#722ED1',
										}}
									>
										{translate('searchChange', language)}
									</Typography>
								</div>
							}
							ifArrowColor="#722ED1"
							menuPosition="left"
							height="400px"
							activeCriterias={activeCriterias}
							testId="calls_btns_change-filters"
						/>
					</div>
				</DisableMask>
				<div>
					{activeCriterias.length > 0 && (
						<SaveCriterias
							currentTemplate={currentTemplate}
							handleSelectChange={handleSelectChange}
							removeAllCriteriasHandler={removeAllCriteriasHandler}
							handleCreateNewTemplateButtonClick={handleCreateNewTemplateButtonClick}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchControls;
