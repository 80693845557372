import { translate } from 'localizations';

export const generateSortArray = (language: string, page: string) => {
	const sortArray = [
		{ value: 'priority', sort_desc: true, label: translate('high_priority_first', language) },
		{ value: 'priority', sort_desc: false, label: translate('low_priority_first', language) },
		{ value: 'title', sort_desc: false, label: translate('alphabetically', language) },
		{ value: 'title', sort_desc: true, label: translate('alphabetically_from_the_end', language) },
		{ value: 'add_time', sort_desc: true, label: translate('first_new', language) },
		{ value: 'add_time', sort_desc: false, label: translate('first_old', language) },
		{ value: 'update_time', sort_desc: true, label: translate('updated_first', language) },
		{ value: 'update_time', sort_desc: false, label: translate('not_updated_first', language) },
	];

	if (page === 'dicts') {
		sortArray.splice(0, 2);
	}

	return sortArray;
};
