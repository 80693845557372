import React, { FC, memo } from 'react';
import { TagThemesTypes } from 'pages/Calls/types';
import { translate } from 'localizations';
import { LoadingButton } from '@mui/lab';
import { useToggleOpen } from 'hooks';
import cn from 'classnames';
import { getUrlForDev } from 'utils/url';
import { TagGroup } from 'widgets/ManualTagging/types';
import s from './styles.module.scss';
import TagTablet from './ui/TagTablet';
import TagSimple from './ui/TagSimple';
import ModalWindow from '../ModalWindow/ModalWindow';
import { MenuTag, TagDetailedInfo, CaseTag } from './type';
import UniversalButton from '../Buttons/UniversalButton/UniversalButton';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

interface ReusableTagProps {
	id?: string | null | undefined;
	name: string | undefined;
	value: string | null;
	theme: TagThemesTypes;
	language: string;
	count?: number | null;
	additionalStyles?: React.CSSProperties;
	cursor?: string;
	isStack?: boolean; // вид стопки, обычно внутри коммуникации
	accessRights: boolean | undefined;
	settings?: boolean; // флаг включения меню при ховере
	handleClickTag: (id: string | null, tagGroup: TagGroup | null) => void;
	isActiveTag: boolean; // флаг активного тега по клику
	onClick?: (e: any) => void;
	fragmentInfo?: TagDetailedInfo | null;
	isErrorTagDetailedInfo?: FetchBaseQueryError | SerializedError | undefined; // флаг ошибки загрузки инфы о теге
	deleteTag?: ((id: string) => Promise<void>) | undefined; // удаление тега
	handleRestoreTag?: (id: string) => Promise<void>; // восстановление тега
	toggleModalManual?: () => void; // закрытие модалки в восстановлении тегов
	isLoading?: boolean;
	userId?: string;
	tagGroup: TagGroup | null;
}

const NewTagComponent: FC<ReusableTagProps> = ({
	id,
	name,
	value,
	theme,
	count,
	additionalStyles,
	isStack,
	accessRights,
	settings,
	language,
	isActiveTag,
	handleClickTag,
	onClick,
	deleteTag,
	handleRestoreTag,
	toggleModalManual,
	isErrorTagDetailedInfo,
	isLoading,
	userId,
	tagGroup,
	fragmentInfo = null,
	cursor = 'default',
}) => {
	const className = `${theme}Tag`;
	const maxLength = 20;
	const tagLength = name?.length || 0;
	const valueLength = value ? value.length : 0;

	const hasTagTooltip = tagLength > maxLength;
	const hasValueTooltip = valueLength > maxLength;

	const classNameTablet = isStack ? s.tagTabletStack : s.tagTablet;

	const { toggle: handleModalToDeleteTag, isShowing: isOpenModalToDeleteTag } = useToggleOpen();
	const { toggle: handleModalToRestoreTag, isShowing: isOpenModalToRestoreTag } = useToggleOpen();
	const textModal = translate('hiddenTagsReset', language);

	const host = window.origin;

	const getLinkRules = (fragmentInfo: TagDetailedInfo | null) => {
		const hrefWithEnv = getUrlForDev(
			`${language}/${userId}/tags?group=${fragmentInfo?.tagRuleGroupId}&id=${fragmentInfo?.tagRuleId}`,
		);
		return `${host}${hrefWithEnv}`;
	};
	const getLinkDict = (fragmentInfo: TagDetailedInfo | null) => {
		const hrefWithEnv = getUrlForDev(
			`${language}/${userId}/dictionaries?group=${fragmentInfo?.matchDictionaryGroupId}&id=${fragmentInfo?.matchDictionaryId}`,
		);
		return `${host}${hrefWithEnv}`;
	};

	const getMenu = React.useMemo(() => {
		const caseTagType: CaseTag = handleRestoreTag
			? 'restore'
			: fragmentInfo || isErrorTagDetailedInfo
			? 'fragment'
			: 'usual';
		let menu: MenuTag[] = [];
		switch (caseTagType) {
			case 'restore':
				menu = [
					{
						id: 'restore',
						title: translate('restoreButton', language),
						onClick: () => {
							handleModalToRestoreTag();
						},
					},
				];
				break;
			case 'usual':
				menu = [
					{
						id: 'delete',
						title: translate('deleteButton', language),
						onClick: () => {
							handleModalToDeleteTag();
						},
					},
				];
				break;
			case 'fragment':
				if (isErrorTagDetailedInfo) {
					menu = [
						{
							id: 'errorTagInfo',
							title: translate('noTagDetailInfo', language),
						},
						{
							id: 'delete',
							title: translate('deleteButton', language),
							lineBefore: true,
							onClick: () => {
								handleModalToDeleteTag();
							},
						},
					];
				} else {
					menu = [
						{
							id: 'phraseDictionary',
							title: fragmentInfo?.matchDictionaryPhrase,
							subTitle: fragmentInfo?.matchDictionaryId
								? translate('phraseDictionary', language)
								: translate('phraseRule', language),
						},
						{
							id: 'phraseCall',
							title: fragmentInfo?.matchData,
							subTitle: translate('phraseCall', language),
						},
						{
							id: 'linkToRule',
							title: translate('linkToRule', language),
							hide: !(fragmentInfo?.tagRuleGroupId && fragmentInfo?.tagRuleId),
							onClick: () => {
								window.open(getLinkRules(fragmentInfo), '_blank');
							},
							lineBefore: true,
						},
						{
							id: 'linkToDict',
							title: translate('linkToDict', language),
							hide: !(fragmentInfo?.matchDictionaryGroupId && fragmentInfo?.matchDictionaryId),
							onClick: () => {
								window.open(getLinkDict(fragmentInfo), '_blank');
							},
						},
						{
							id: 'delete',
							title: translate('deleteButton', language),
							onClick: () => {
								handleModalToDeleteTag();
							},
							lineBefore: true,
						},
					];
				}
				break;
			default:
				menu = [
					{
						id: 'delete',
						title: translate('deleteButton', language),
						onClick: () => {
							handleModalToDeleteTag();
						},
					},
				];
				break;
		}
		return menu;
	}, [deleteTag, handleRestoreTag, fragmentInfo, isErrorTagDetailedInfo]);

	const deleteTagAsync = async () => {
		if (id) {
			await deleteTag?.(id);
			handleModalToDeleteTag();
		}
	};
	const restoreTagAsync = async () => {
		if (id) {
			await handleRestoreTag?.(id);
			handleModalToRestoreTag();
			toggleModalManual?.();
		}
	};
	return (
		<div
			className={cn(s[className], { [s.tagMinWidth]: settings })}
			style={{ cursor: cursor, ...additionalStyles }}
			onClick={onClick ?? undefined}
		>
			{value ? (
				// вариант со значением = таблетка
				<TagTablet
					id={id}
					name={name}
					count={count}
					value={value}
					theme={theme}
					menu={getMenu}
					isLoading={isLoading}
					language={language}
					tagGroup={tagGroup}
					isActiveTag={isActiveTag}
					settings={settings}
					accessRights={accessRights}
					classNameTablet={classNameTablet}
					hasTagTooltip={hasTagTooltip}
					hasValueTooltip={hasValueTooltip}
					handleClickTag={handleClickTag}
				/>
			) : (
				// вариант обычный
				<TagSimple
					id={id}
					name={name}
					count={count}
					theme={theme}
					menu={getMenu}
					isLoading={isLoading}
					language={language}
					tagGroup={tagGroup}
					isActiveTag={isActiveTag}
					settings={settings}
					accessRights={accessRights}
					hasTagTooltip={hasTagTooltip}
					handleClickTag={handleClickTag}
				/>
			)}
			{/* Модальные окна для манипулирования тегами */}
			<ModalWindow
				isMWOpen={isOpenModalToDeleteTag}
				handleMWClose={handleModalToDeleteTag}
				text={translate('manualModalDelete', language)}
			>
				<div className={s.modalBtns}>
					<LoadingButton variant="contained" color="primary" type="submit" onClick={deleteTagAsync}>
						{translate('delete', language)}
					</LoadingButton>
					<LoadingButton variant="outlined" color="primary" onClick={handleModalToDeleteTag}>
						{translate('cancelButton', language)}
					</LoadingButton>
				</div>
			</ModalWindow>
			<ModalWindow
				isMWOpen={isOpenModalToRestoreTag}
				handleMWClose={handleModalToRestoreTag}
				text={textModal}
				width="600px"
				classNameTitleText={s.titleModal}
				blockFotter={
					<div className={s.buttons}>
						<UniversalButton
							buttonStyle="ghost"
							text={translate('cancelButton', language)}
							handleClick={handleModalToRestoreTag}
							testId={'restore_tag_cancel'}
						/>
						<UniversalButton
							buttonStyle="filled"
							text={translate('restoreButton', language)}
							handleClick={restoreTagAsync}
							testId={'restore_tag_ok'}
						/>
					</div>
				}
			>
				<div className={s.contentModal}>{translate('hiddenTagsResetQue', language)}</div>
			</ModalWindow>
		</div>
	);
};

export default memo(NewTagComponent);
