import { useSnackbar } from 'notistack';
import { SnackbarParamsType, createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';

const useMessages = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { language } = useAppSelector((state) => state.lang);

	const getSnackbar = (type: SnackbarParamsType, text: string) => {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type,
				text: translate(text, language),
				time: 3500,
			}),
		);
	};

	const showSuccessMessageAdd = () => {
		getSnackbar('success', 'manualTagSuccessAdd');
	};
	const showSuccessMessageDelete = () => {
		getSnackbar('success', 'manualTagSuccessDelete');
	};
	const showErrorMessageAdd = () => {
		getSnackbar('error', 'manualTagErrorAdd');
	};
	const showErrorMessageDelete = () => {
		getSnackbar('error', 'manualTagErrorDelete');
	};
	const showErrorValidate = () => {
		getSnackbar('error', 'validateTag');
	};
	const showErrorSizeValidate = () => {
		getSnackbar('error', 'reportSaveLongTitle');
	};
	const showErrorUniqueValidate = () => {
		getSnackbar('error', 'validateTagUnique');
	};

	const showSuccessRestoreTag = () => {
		getSnackbar('success', 'restoreTagSuccess');
	};
	const showErrorRestoreTag = () => {
		getSnackbar('error', 'restoreTagError');
	};


	return {
		showSuccessMessageAdd,
		showSuccessMessageDelete,
		showErrorMessageAdd,
		showErrorMessageDelete,
		showErrorValidate,
		showErrorSizeValidate,
		showErrorUniqueValidate,
		showSuccessRestoreTag,
		showErrorRestoreTag,

	};
};

export default useMessages;
