import { useCallback, useState } from 'react';
import { TagGroup } from 'widgets/ManualTagging/types';

export const useActiveTag = () => {
	const [clickedTagId, setClickedTagId] = useState<string | null>(null);
	const [clickedTagGroup, setClickedTagGroup] = useState<TagGroup | null>(null);

	const handleClickTagSettings = useCallback(
		(id: string | null, group: TagGroup | null) => {
			setClickedTagId(id);
			setClickedTagGroup(group);
		},
		[setClickedTagId, setClickedTagGroup],
	);
	return {
		clickedTagId,
		clickedTagGroup,
		handleClickTagSettings,
	};
};
