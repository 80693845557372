// TODO: починить типы для колонок и строк

import { IChecklistSuggest, ISuggest } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { getFullCriteria } from 'pages/Calls/CallsSearch/Search';
import { GlobalFilterCriteria } from 'store/markupRules/types';
import {
	AdditionalColumns,
	AdditionalRows,
	RowsGroupByParametersType,
	ReportParametersType,
	ReportByHash,
	ColsGroupByParametersType,
	TagNameValueValueType,
	SearchItemsValueType,
	TagNameListValueType,
	ViewOptionsRowsOrColumns,
	TagNameListValueRowType,
	ViewOptionsRowsOrColumnsResponse,
} from 'store/reports/reports.types';
import { CriteriasType } from 'store/search/search.types';
import { convertComplexValuesIntoTags } from 'utils/convertDataForSearchFilters/convertData';
import { SimpleObject } from 'pages/Reports/types/ReportsTypes';
import { optionsCreatorSimple } from 'utils/optionsCreator';
import { defaultAdditionalColumn, defaultAdditionalRow } from './defaultValues';

/* Приводим данные отчета с бэка на фронт */

export const convertAdditionalParams = (additionalParams: ViewOptionsRowsOrColumnsResponse['additional_params']) =>
	additionalParams.map((parameter) => {
		const param_type = parameter.param_type;
		switch (param_type) {
			case 'view_checklist_answer_avg_param':
				return {
					checklists: [optionsCreatorSimple(JSON.stringify(parameter))],
					param_type,
				};
			case 'view_checklist_answer_avg_percent_param':
				return {
					checklists: [optionsCreatorSimple(JSON.stringify(parameter))],
					param_type,
				};
			case 'view_comment_param':
				return {
					comment_title: [optionsCreatorSimple(parameter.comment_title)],
					param_type,
				};
			case 'view_checklist_avg_param':
				return {
					checklists: [optionsCreatorSimple(JSON.stringify(parameter))],
					param_type,
				};
			case 'view_checklist_avg_percent_param':
				return {
					checklists: [optionsCreatorSimple(JSON.stringify(parameter))],
					param_type,
				};
			case 'view_checklist_answer_text_avg_param':
				return {
					checklists: [optionsCreatorSimple(JSON.stringify(parameter))],
					param_type,
				};
			default:
				return parameter;
		}
	});

export const convertViewOptions = (options: ViewOptionsRowsOrColumnsResponse) => {
	let convertedOptions = { ...options };

	// проверяем наличие свойства show_call_dt и отстутствие first/last в старых отчетах и заменяем
	if (!options?.show_first_call_dt && !options?.show_last_call_dt && options.show_call_dt) {
		convertedOptions = {
			...convertedOptions,
			show_call_dt: false, // сбрасываем значение старого свойства
			show_first_call_dt: true,
			show_last_call_dt: true,
		};
	}
	return {
		...convertedOptions,
		additional_params: convertAdditionalParams(options.additional_params),
	};
};

export const convertRows = (
	data: RowsGroupByParametersType[],
	selectTranslatedNames: SimpleObject,
	view_options: ViewOptionsRowsOrColumnsResponse,
) => {
	let convertedRows: AdditionalRows[] = [];
	convertedRows = data.map((row) => {
		let result;
		const viewOptions = convertViewOptions(view_options);
		switch (row.group_by) {
			case 'time':
				result = {
					...defaultAdditionalRow,
					groupByRowsValue: {
						value: row.group_by,
						label: selectTranslatedNames[row.group_by],
					},
					timeRowsReportValue: { value: row.value, label: selectTranslatedNames[row.value] },
					checkboxSplitRow: false,
					viewOptions,
				};
				break;

			case 'tag_name_list':
				const tagNameListItem: TagNameListValueRowType = row.value;
				result = {
					...defaultAdditionalRow,
					groupByRowsValue: {
						value: row.group_by,
						label: selectTranslatedNames[row.group_by],
					},
					tagNameValueValid: true,
					tagsNameList: {
						values: tagNameListItem.values.map((value) => ({ value, label: value })),
						values_isValid: Boolean(tagNameListItem.values.length),
						split: tagNameListItem.split,
						row_name: tagNameListItem.row_name,
						row_name_isValid: Boolean(tagNameListItem.row_name),
						only_to_first_appropriate: tagNameListItem.only_to_first_appropriate,
					},
					viewOptions,
				};
				break;

			case 'tag_name_value':
				result = {
					...defaultAdditionalRow,
					groupByRowsValue: {
						value: row.group_by,
						label: selectTranslatedNames[row.group_by],
					},
					checkboxSplitRow: row.value?.split,
					tagNameValueRow: {
						value: row.value?.name,
						label: row.value?.name,
					},
					tagNameRowListWithValue: row.value?.values.map((valueItem) => ({
						value: valueItem,
						label: valueItem,
					})),
					tagNameValueValid: Boolean(row.value?.name),
					tagValuesRow: [],
					checkboxToFirstAppropriate: row.value?.only_to_first_appropriate,
					viewOptions,
				};
				break;

			case 'operator_phone':
				result = {
					...defaultAdditionalRow,
					groupByRowsValue: {
						value: row.group_by,
						label: selectTranslatedNames[row.group_by],
					},
					tagNameValueValid: true,
					timeRowsReportValue: { value: 'day', label: selectTranslatedNames.day },
					checkboxSplitRow: false,
					viewOptions,
				};
				break;

			case 'call':
				result = {
					...defaultAdditionalRow,
					groupByRowsValue: {
						value: row.group_by,
						label: selectTranslatedNames[row.group_by],
					},
					tagNameValueValid: true,
					timeRowsReportValue: { value: 'day', label: selectTranslatedNames.day },
					checkboxSplitRow: false,
					viewOptions,
				};
				break;

			default:
				result = {
					...defaultAdditionalRow,
					groupByRowsValue: {
						value: row.group_by,
						label: selectTranslatedNames[row.group_by],
					},
					tagNameValueValid: true,
					viewOptions,
				};
				break;
		}
		return result;
	});

	return convertedRows;
};

export const convertColumns = (data: ColsGroupByParametersType[], selectTranslatedNames: SimpleObject) => {
	let convertedColumns: AdditionalColumns[] = [];
	convertedColumns = data.map((column) => {
		let result;
		switch (column.group_by) {
			case 'tag_name_value':
				const tagNameItem: TagNameValueValueType = column.value;
				result = {
					...defaultAdditionalColumn,
					tagNameValue: {
						valueTag: { value: tagNameItem.name, label: tagNameItem.name },
						//  запрашиваем в компоненте
						optionsTag: [],
						values: tagNameItem.values?.map((value) => ({ value, label: value })),
						name: tagNameItem.name,
						split: tagNameItem.split,
						only_to_first_appropriate: tagNameItem.only_to_first_appropriate,
						isValid: Boolean(tagNameItem.name),
					},
				};
				break;

			case 'tag_name_list':
				const tagNameListItem: TagNameListValueType = column.value;
				result = {
					...defaultAdditionalColumn,
					tagsNameList: {
						values: tagNameListItem.values.map((value) => ({ value, label: value })),
						values_isValid: Boolean(tagNameListItem.values.length),
						split: tagNameListItem.split,
						col_name: tagNameListItem.col_name,
						col_name_isValid: Boolean(tagNameListItem.col_name),
						only_to_first_appropriate: tagNameListItem.only_to_first_appropriate,
					},
				};
				break;
			case 'search_items':
				const searchItemsItem: SearchItemsValueType = column.value;
				// остальные параметры устанавливаются внутри компонента
				result = {
					...defaultAdditionalColumn,
					callFilters: {
						options: [],
						values: [],
						activeValues: searchItemsItem.search_items,
						nameColumn: {
							value: searchItemsItem.col_name,
							isValid: Boolean(searchItemsItem.col_name),
						},
					},
				};
				break;
			default:
				result = { ...defaultAdditionalColumn };
				break;
		}
		return {
			groupByColumnsValue: { value: column.group_by, label: selectTranslatedNames[column.group_by] },
			...result,
			viewOptions: convertViewOptions(column.view_options),
		};
	});
	return convertedColumns;
};

export const convertCallSearchItems = (
	callSearchItems: GlobalFilterCriteria[],
	allCriterias: CriteriasType[],
	criteriaWithComplexValues: string[],
) => {
	let activeSearchCriterias: GlobalFilterCriteria[] = [];
	if (allCriterias.length) {
		callSearchItems.forEach((searchItem) => {
			const foundCriteria = getFullCriteria(searchItem.key, allCriterias);

			if (foundCriteria) activeSearchCriterias.push({ ...foundCriteria });
		});
	} else {
		activeSearchCriterias = [...callSearchItems];
	}

	activeSearchCriterias.forEach((criteria, index) => {
		if (criteriaWithComplexValues.includes(callSearchItems[index].key)) {
			criteria.values = !callSearchItems[index].complexValues
				? callSearchItems[index].values
				: convertComplexValuesIntoTags(
						callSearchItems[index].complexValues as ISuggest[] | IChecklistSuggest[],
				  );
			criteria.logic = callSearchItems[index].logic;
			criteria.conditionItemId = index;
		} else {
			criteria.values = callSearchItems[index].values;
		}
	});
	const newActiveSearchItems = activeSearchCriterias.map((item, i) => {
		if (criteriaWithComplexValues.includes(item.key)) {
			return {
				...item,
				values: !callSearchItems[i].complexValues
					? callSearchItems[i].values
					: convertComplexValuesIntoTags(
							callSearchItems[i].complexValues as ISuggest[] | IChecklistSuggest[],
					  ),
				logic: callSearchItems[i].logic || 'and',
				conditionItemId: i,
			};
		}
		return {
			...item,
			values: callSearchItems[i].values,
		};
	});
	return newActiveSearchItems;
};

export const convertSavedReport = (
	report: ReportParametersType,
	selectTranslatedNames: SimpleObject,
	allCriterias: CriteriasType[],
	criteriaWithComplexValues: string[],
) => {
	const rowsGroupBy = report.rows_group_by;
	const additionalRowsFromData = convertRows(rowsGroupBy, selectTranslatedNames, rowsGroupBy[0].view_options);

	const rows = [...additionalRowsFromData];

	const columns = convertColumns(report.cols_group_by, selectTranslatedNames);

	const convertedCallSearchItems: CriteriasType[] = convertCallSearchItems(
		report.call_search_items,
		allCriterias,
		criteriaWithComplexValues,
	);

	const activeReport = {
		...report,
		cols_group_by: columns,
		rows_group_by: rows,
		call_search_items: convertedCallSearchItems,
	};

	return {
		report: activeReport,
		additionalRows: additionalRowsFromData,
		additionalColumns: columns,
		callSearch: convertedCallSearchItems,
	};
};

export const convertSavedReportFromHash = (
	reportData: ReportByHash,
	selectTranslatedNames: SimpleObject,
	allCriterias: CriteriasType[],
	criteriaWithComplexValues: string[],
) => {
	const reportParameters = reportData.report_parameters;

	const additionalRowsFromData = convertRows(
		reportParameters.rows_group_by,
		selectTranslatedNames,
		reportParameters.rows_group_by[0].view_options,
	);

	const columns = convertColumns(reportParameters.cols_group_by, selectTranslatedNames);

	const rows = [...additionalRowsFromData];

	const convertedCallSearchItems: CriteriasType[] = convertCallSearchItems(
		reportParameters.call_search_items,
		allCriterias,
		criteriaWithComplexValues,
	);

	const activeReport = {
		...reportData,
		report_parameters: {
			...reportParameters,
			cols_group_by: columns,
			rows_group_by: rows,
			call_search_items: convertedCallSearchItems,
		},
	};

	return {
		report: activeReport,
		additionalRows: additionalRowsFromData,
		additionalColumns: columns,
		callSearch: convertedCallSearchItems,
	};
};
