import { FC, SVGProps } from 'react';

const Audit: FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.625 3.90625C4.55625 3.90625 4.5 3.9625 4.5 4.03125V4.78125C4.5 4.85 4.55625 4.90625 4.625 4.90625H10.625C10.6938 4.90625 10.75 4.85 10.75 4.78125V4.03125C10.75 3.9625 10.6938 3.90625 10.625 3.90625H4.625ZM7.5 6.15625H4.625C4.55625 6.15625 4.5 6.2125 4.5 6.28125V7.03125C4.5 7.1 4.55625 7.15625 4.625 7.15625H7.5C7.56875 7.15625 7.625 7.1 7.625 7.03125V6.28125C7.625 6.2125 7.56875 6.15625 7.5 6.15625ZM6.75 13.3125H3.25V2.3125H12V7.3125C12 7.38125 12.0562 7.4375 12.125 7.4375H13C13.0688 7.4375 13.125 7.38125 13.125 7.3125V1.6875C13.125 1.41094 12.9016 1.1875 12.625 1.1875H2.625C2.34844 1.1875 2.125 1.41094 2.125 1.6875V13.9375C2.125 14.2141 2.34844 14.4375 2.625 14.4375H6.75C6.81875 14.4375 6.875 14.3813 6.875 14.3125V13.4375C6.875 13.3687 6.81875 13.3125 6.75 13.3125ZM13.625 11.9375H11.375V11.3656C12.0984 11.15 12.625 10.4812 12.625 9.6875C12.625 8.72031 11.8422 7.9375 10.875 7.9375C9.90781 7.9375 9.125 8.72031 9.125 9.6875C9.125 10.4797 9.65156 11.15 10.375 11.3656V11.9375H8.125C7.9875 11.9375 7.875 12.05 7.875 12.1875V14.5625C7.875 14.7 7.9875 14.8125 8.125 14.8125H13.625C13.7625 14.8125 13.875 14.7 13.875 14.5625V12.1875C13.875 12.05 13.7625 11.9375 13.625 11.9375ZM10.0938 9.6875C10.0938 9.25625 10.4437 8.90625 10.875 8.90625C11.3063 8.90625 11.6562 9.25625 11.6562 9.6875C11.6562 10.1188 11.3063 10.4688 10.875 10.4688C10.4437 10.4688 10.0938 10.1188 10.0938 9.6875ZM12.9062 13.8438H8.84375V12.9062H12.9062V13.8438Z"
			fill="#722ED1"
		/>
	</svg>
);

export default Audit;
