import { useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';

const useHintTranslations = () => {
	const { language } = useAppSelector((state) => state.lang);

	return {
		name: translate('hint_name', language),
		login: translate('hint_login', language),
		email: translate('hint_email', language),
		password: (
			<div>
				{translate('hint_password', language)}
				<ul>
					<li>{translate('hint_password_1', language)}</li>
					<li>{translate('hint_password_2', language)}</li>
					<li>{translate('hint_password_3', language)}</li>
					<li>{translate('hint_password_4', language)}</li>
				</ul>
			</div>
		),
		role: translate('hint_role', language),
		industry: translate('hint_industry', language),
		partner: translate('hint_partner', language),
		comment: translate('hint_comment', language),
		sttLanguage: translate('hint_stt_lang', language),
		sttEngine: translate('hint_stt_engine', language),
		sttModel: translate('hint_stt_model', language),
		time: translate('hint_time', language),
		phone: translate('hint_phone', language),
		timezone: translate('hint_timezone', language),
		billingUserId: translate('hint_billing_user_id', language),
	};
};

export default useHintTranslations;
