import React, { FC } from 'react';
import { translate } from 'localizations';
import STT from 'widgets/STT/STT';

import cls from './styles.module.scss';
import { SttValuesForRequest } from 'widgets/STT/namespaces';

interface STTProps {
	language: string;
	isSingleCall: boolean;
	callsCount: number;
	userInfo: any;
	toggleModalSTT: () => void;
	onIconClickSTT: (values: SttValuesForRequest) => void;
}

const SttModalInner: FC<STTProps> = (props) => {
	const { language, isSingleCall, callsCount, userInfo, toggleModalSTT, onIconClickSTT } = props;

	return (
		<div>
			{!isSingleCall && (
				<>
					<div className={cls.sttAllFoudCalls}>{`${translate(
						'allFoundCalls',
						language,
					)} ${callsCount})`}</div>
					<div className={cls.sttTitle}>{translate('setLangEngineModel', language)} </div>
				</>
			)}
			<STT
				language={language}
				user={userInfo}
				onSubmitFunction={(values) => {
					toggleModalSTT();
					onIconClickSTT(values);
				}}
				buttonTitle={translate('stt', language)}
			/>
		</div>
	);
};

export default SttModalInner;
