import React from 'react';
import { FC, useState } from 'react';
import cn from 'classnames';
import { Tooltip } from 'components/common';
import { TagGroup } from 'widgets/ManualTagging/types';
import s from '../styles.module.scss';
import IconsForTag from './TagWIthIcons/components/IconsForTag';
import { TagThemesTypes } from 'pages/Calls/types';
import { themesColors } from './const/consts';
import { MenuTag } from '../type';

/*  
	Тег простой, без значения.

	есть проверка на тултип hasTagTooltip

	если счетчик (count) больше одного, то всплывающего меню у тега нет
*/

export interface TagSimpleProps {
	id: string | null | undefined;
	theme: TagThemesTypes;
	hasTagTooltip: boolean;
	name: string | undefined;
	count: number | null | undefined;
	accessRights: boolean | undefined;
	settings?: boolean | undefined;
	isActiveTag: boolean;
	handleClickTag: (id: string | null, tagGroup: TagGroup | null) => void;
	menu: MenuTag[];
	language: string;
	isLoading?: boolean;
	tagGroup: TagGroup | null;
}

const TagSimple: FC<TagSimpleProps> = ({
	id,
	hasTagTooltip,
	name,
	count,
	settings,
	theme,
	accessRights,
	isActiveTag,
	handleClickTag,
	language,
	menu,
	isLoading,
	tagGroup,
}) => {
	const [isHover, setIsHover] = useState(false);

	const classNameBg = (isHover || isActiveTag) && settings ? `${theme}_bgTag` : '';
	const styles = isHover
		? {
				backgroundColor: `${themesColors[theme].hoverBg} !important`,
		  }
		: {};

	return (
		<div
			className={cn(s.tagSimple, s[classNameBg])}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			data-testid={`tag-${id}`}
		>
			{hasTagTooltip ? (
				<>
					{count && count > 1 ? (
						<Tooltip title={name || ''} placement="top" background="dark">
							<span className={s.tagFlex} style={styles}>
								<div className={s.ellipsis}>{name}</div>
								{count && count > 1 && <div className={s.count}>x{count}</div>}
							</span>
						</Tooltip>
					) : (
						<Tooltip title={name || ''} placement="top" background="dark">
							<IconsForTag
								id={id}
								menu={menu}
								theme={theme}
								isHover={isHover}
								active={settings}
								tagGroup={tagGroup}
								language={language}
								isLoading={isLoading}
								isActiveTag={isActiveTag}
								accessRights={accessRights}
								handleClickTag={handleClickTag}
							>
								<span className={s.tagFlex} style={styles}>
									<div className={s.ellipsis}>{name}</div>
								</span>
							</IconsForTag>
						</Tooltip>
					)}
				</>
			) : (
				<>
					{count && count > 1 ? (
						<span className={s.tagFlex} style={styles}>
							{name}
							{count && count > 1 && <div className={s.count}>x{count}</div>}
						</span>
					) : (
						<IconsForTag
							id={id}
							menu={menu}
							isLoading={isLoading}
							language={language}
							isActiveTag={isActiveTag}
							tagGroup={tagGroup}
							theme={theme}
							active={settings}
							isHover={isHover}
							accessRights={accessRights}
							handleClickTag={handleClickTag}
						>
							<span className={s.tagFlex} style={styles}>
								<div className={s.ellipsis}>{name}</div>
							</span>
						</IconsForTag>
					)}
				</>
			)}
		</div>
	);
};

export default React.memo(TagSimple);
