import { ExtendedCallTagType } from 'store/calls/calls.types';

export const dash = '—';

export const getTagDetailedConvertedInfo = (tagDetailedInfo: ExtendedCallTagType) => {
	if (tagDetailedInfo)
		return {
			id: tagDetailedInfo.id,
			matchData: tagDetailedInfo.matchData ?? dash,
			tagRuleGroupId: tagDetailedInfo.tagRuleGroupId,
			tagRuleId: tagDetailedInfo.tagRuleId,
			matchDictionaryId: tagDetailedInfo.matchDictionaryId,
			matchDictionaryPhrase: tagDetailedInfo.matchDictionaryPhrase ?? dash,
			matchDictionaryGroupId: tagDetailedInfo.matchDictionaryGroupId,
		};
};
