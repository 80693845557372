import { FC, useEffect } from 'react';
import { getMeAccessRights } from 'store/user/user.slice';
import { useAppDispatch } from 'hooks/redux';
import CallPage from '../Call/CallPage';
import { useLocation } from 'react-router-dom';

import s from './styles.module.scss';

const CalliFrame: FC = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const idFromUrl = queryParams.get('id');
	const tokenFromUrl = queryParams.get('token');
	const lengFromUrl = queryParams.get('language');

	window.sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: tokenFromUrl }));

	useEffect(() => {
		dispatch(getMeAccessRights());
	}, []);

	return (
		<CallPage
			callPageBoxStyles={s.box}
			showOpenLink={false}
			showParams={false}
			marginTop="0"
			lengFromUrl={lengFromUrl}
			idFromUrl={idFromUrl}
			tokenFromUrl={tokenFromUrl}
		/>
	);
};

export default CalliFrame;
