import { FC, ReactNode, useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { TagFormat } from 'widgets/ManualTagging/types';
import { createOptionsSelect } from 'widgets/ManualTagging/utils/utils';
import { Dropdown, DropdownIndicator } from './components/CustomMenuComponents';
import s from './styles.module.scss';
import { NewTagComponent } from '..';
import { translate } from 'localizations';

const selectStyles: StylesConfig<TagFormat, false> = {
	control: (provided) => ({
		...provided,
		minWidth: 240,
		margin: '8px 8px 16px 8px',
		borderColor: '#cdd5df',
		// '&:hover': {
		// 	borderColor: 'red',
		// },
	}),
	menuList: (base) => ({
		...base,

		'::-webkit-scrollbar': {
			width: '3px',
			height: '0px',
		},
		'::-webkit-scrollbar-track': {
			background: 'white',
		},
		'::-webkit-scrollbar-thumb': {
			borderRadius: '5px',
			background: '#A3AEBE',
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: '#A3AEBE',
		},
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#A3AEBE',
	}),
};

interface CustomSelectWithSearchProps {
	testId: string;
	options: TagFormat[];
	value: TagFormat | null | undefined;
	Target: ReactNode;
	onChange: (e: TagFormat | null | undefined) => void;
	handleChangeInput: (e: TagFormat) => void;
	handleValidation: (e: undefined | null | string, index: number) => boolean;
	onClickOutside: (index: number) => void;
	index: number;
	isNewValue: boolean;
	placeholder: string;
	language: string;
}

const CustomSelectWithSearch: FC<CustomSelectWithSearchProps> = ({
	options,
	value,
	testId,
	Target,
	onChange,
	handleValidation,
	handleChangeInput,
	onClickOutside,
	isNewValue,
	placeholder,
	language,
	index,
}) => {
	const [isOpen, setIsOpen] = useState(isNewValue || false);
	const [valueLocal, setValue] = useState<TagFormat | undefined | null>(value);
	const [valueInput, setValueInput] = useState('');

	useEffect(() => {
		if (value) setValue(value);
	}, [value]);

	const onClickTarget = () => {
		setIsOpen((prev) => !prev);
	};

	const closeModal = () => {
		setIsOpen(false);
		if (isNewValue && !valueLocal?.value.trim()) {
			onClickOutside(index);
		}
	};

	const CustomOption = ({ innerProps, isDisabled, value }) =>
		!isDisabled ? (
			<div style={{ padding: '2px 8px', width: 'auto' }} {...innerProps}>
				<NewTagComponent
					id={''}
					name={value}
					theme="green"
					value={null}
					cursor="pointer"
					tagGroup={null}
					language={language}
					additionalStyles={{ maxWidth: 'min-content' }}
					accessRights={true}
					handleClickTag={() => {}}
					isActiveTag={false}
				></NewTagComponent>
			</div>
		) : null;

	return (
		<div className={s.dropdown} data-testid={testId || ''}>
			<Dropdown
				isOpen={isOpen}
				onClose={closeModal}
				target={
					<div onClick={onClickTarget} className={s.target}>
						{Target}
					</div>
				}
			>
				<Select
					autoFocus
					backspaceRemovesValue={false}
					components={{ DropdownIndicator, IndicatorSeparator: null, Option: CustomOption }}
					controlShouldRenderValue={false}
					hideSelectedOptions={false}
					isClearable={false}
					menuIsOpen
					onChange={(newValue) => {
						setValue(newValue);
						if (handleValidation(newValue?.value, index)) {
							onChange(newValue);
						}
						closeModal();
					}}
					onInputChange={(e) => {
						const convertedValue = createOptionsSelect(e);
						handleChangeInput(convertedValue);
						setValue(convertedValue);
						setValueInput(e);
					}}
					onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
						if (e.key === 'Enter') {
							if (handleValidation((e.target as HTMLInputElement)?.value, index)) {
								onChange(valueLocal);
							}
							e.preventDefault();
							closeModal();
						} else if (e.key === 'Escape') {
							closeModal();
						} else if (e.code === 'Space' && !(e.target as HTMLInputElement)?.value.length) {
							e.preventDefault();
						}
					}}
					options={options}
					placeholder={placeholder}
					styles={selectStyles}
					tabSelectsValue={false}
					value={valueLocal}
					inputValue={valueInput}
					noOptionsMessage={() => {
						return translate('manualEnter', language);
					}}
				/>
			</Dropdown>
		</div>
	);
};

export default CustomSelectWithSearch;
