/* eslint-disable */
import {
	AddParamTagName,
	AdditionalColumns,
	AddParamChecklistQuestionLocal,
	AdditionalRows,
	ReportParametersType,
	ViewOptionsRowsOrColumns,
	AddParamChecklistLocal,
	ViewOptionsRowsOrColumnsResponse,
	RowsGroupByParametersTypeResponse,
	AddParamCommentLocal,
	AddParamChecklist,
	AddParamChecklistQuestion,
	AddParamComment,
	AddParamPercentSourceLocal,
	AddParamPercentSource,
	AddParamAvgNumTagName,
} from 'store/reports/reports.types';
import { CriteriasType } from 'store/search/search.types';
import { convertCriteriasForRequest } from 'utils/convertDataForSearchFilters/convertData';
import { criteriaWithComplexValues } from 'configs/searchCriteria';

/* конвертируем данные для построения отчета */

export const convertAdditionalParams = (data: ViewOptionsRowsOrColumns) => {
	const convertedAdditionalParams: ViewOptionsRowsOrColumnsResponse['additional_params'] = [];

	data.additional_params.forEach(
		(
			param:
				| AddParamTagName
				| AddParamAvgNumTagName
				| AddParamChecklistQuestionLocal
				| AddParamChecklistLocal
				| AddParamCommentLocal
				| AddParamPercentSourceLocal,
		) => {
			switch (param.param_type) {
				case 'view_tag_param':
					convertedAdditionalParams.push(param as AddParamTagName);
					break;
				case 'view_avg_num_tag_param':
					convertedAdditionalParams.push(param as AddParamAvgNumTagName);
					break;
				case 'view_checklist_answer_avg_param':
					const questions = param as AddParamChecklistQuestionLocal;
					const valuesQuestions = questions.checklists.map((checklist) => {
						const convertedValues = JSON.parse(checklist.value);
						const { checklist_id, question_id } = convertedValues;
						return {
							checklist_id,
							question_id,
							param_type: questions.param_type,
						};
					});
					convertedAdditionalParams.push(...(valuesQuestions as AddParamChecklistQuestion[]));
					break;
				case 'view_checklist_answer_avg_percent_param':
					const questionsPercent = param as AddParamChecklistQuestionLocal;
					const valuesQuestionsPercent = questionsPercent.checklists.map((checklist) => {
						const convertedValues = JSON.parse(checklist.value);
						const { checklist_id, question_id } = convertedValues;
						return {
							checklist_id,
							question_id,
							param_type: questionsPercent.param_type,
						};
					});
					convertedAdditionalParams.push(...(valuesQuestionsPercent as AddParamChecklistQuestion[]));
					break;
				case 'view_checklist_avg_param':
					const checklists = param as AddParamChecklistLocal;
					const valuesChecklists = checklists.checklists.map((checklist) => {
						const convertedValues = JSON.parse(checklist.value);
						const { checklist_id } = convertedValues;
						return {
							checklist_id,
							param_type: checklists.param_type,
						};
					});
					convertedAdditionalParams.push(...(valuesChecklists as AddParamChecklist[]));
					break;
				case 'view_checklist_avg_percent_param':
					const checklistsPercent = param as AddParamChecklistLocal;
					const valuesChecklistsPercent = checklistsPercent.checklists.map((checklist) => {
						const convertedValues = JSON.parse(checklist.value);
						const { checklist_id } = convertedValues;
						return {
							checklist_id,
							param_type: checklistsPercent.param_type,
						};
					});
					convertedAdditionalParams.push(...(valuesChecklistsPercent as AddParamChecklist[]));
					break;
				case 'view_checklist_answer_text_avg_param':
					const questionsAnswer = param as AddParamChecklistQuestionLocal;
					const valuesQuestionsAnswer = questionsAnswer.checklists.map((checklist) => {
						const convertedValues = JSON.parse(checklist.value);
						const { checklist_id, question_id } = convertedValues;
						return {
							checklist_id,
							question_id,
							param_type: questionsAnswer.param_type,
						};
					});
					convertedAdditionalParams.push(...(valuesQuestionsAnswer as AddParamChecklistQuestion[]));
					break;
				case 'view_comment_param':
					const comments = param as AddParamCommentLocal;
					const valuesComments = comments.comment_title.map((comment) => ({
						comment_title: comment.value,
						param_type: comments.param_type,
					}));
					convertedAdditionalParams.push(...(valuesComments as AddParamComment[]));
					break;
				case 'view_percent_calculating_source_param':
					const filters = param as AddParamPercentSourceLocal;
					const convertedFilters = {
						...filters,
						param_type: filters.param_type,
						search_items: filters?.search_items,
					};
					convertedAdditionalParams.push(convertedFilters as AddParamPercentSource[]);
					break;
				default:
					convertedAdditionalParams.push(param as AddParamTagName);
					break;
			}
		},
	);
	return { ...data, additional_params: convertedAdditionalParams };
};

export const convertAdditionalRows = (
	additionalRows: AdditionalRows[],
	data: ReportParametersType,
): RowsGroupByParametersTypeResponse[] => {
	const additionalParamsDefaultRow = convertAdditionalParams(additionalRows[0].viewOptions);

	const convertedRows: RowsGroupByParametersTypeResponse[] = additionalRows.map((row) => {
		const groupByRow = row.groupByRowsValue.value;
		let groupingValue: any;
		let convertedValue;

		switch (groupByRow) {
			case 'time':
				groupingValue = row.timeRowsReportValue.value;
				break;
			case 'tag_name_value':
				groupingValue = {
					name: row.tagNameValueRow.value,
					values: row.tagNameRowListWithValue.map((listValue) => listValue.value),
					split: row.checkboxSplitRow,
					only_to_first_appropriate: row.checkboxSplitRow ? row.checkboxToFirstAppropriate : false,
				};
				break;
			case 'tag_name_list':
				const valuesList = row.tagsNameList.values.map((valueItem: any) => {
					if (valueItem.value) return valueItem.value;
					else return valueItem;
				});
				groupingValue = {
					row_name: row.tagsNameList.row_name ?? '',
					values: valuesList,
					split: row.tagsNameList.split,
					only_to_first_appropriate: row.tagsNameList.split
						? row.tagsNameList.only_to_first_appropriate
						: false,
				};
				break;

			default:
				groupingValue = null;
				break;
		}
		return (convertedValue = {
			group_by: groupByRow,
			value: groupingValue,
			// view options для доп строк повторяют дефолтную первую строку, поэтому передаем одинаковые данные
			view_options: additionalParamsDefaultRow,
		});
	});
	return convertedRows;
};

export const convertColumns = (requestParams: any, columns: AdditionalColumns[]) => {
	const activeReport = { ...requestParams };
	const convertedColumns = columns.map((column) => {
		const groupByColumn = column.groupByColumnsValue.value;
		let convertedValue: any;

		switch (groupByColumn) {
			case 'tag_name_value':
				const values = column.tagNameValue.values.map((valueItem: any) => {
					if (valueItem.value) return valueItem.value;
					else return valueItem;
				});

				convertedValue = {
					group_by: groupByColumn,
					value: {
						name: column.tagNameValue.name ?? '',
						values,
						split: column.tagNameValue.split,
						only_to_first_appropriate: column.tagNameValue.split
							? column.tagNameValue.only_to_first_appropriate
							: false,
					},
					view_options: convertAdditionalParams(column.viewOptions),
				};
				break;

			case 'tag_name_list':
				const valuesList = column.tagsNameList.values.map((valueItem: any) => {
					if (valueItem.value) return valueItem.value;
					else return valueItem;
				});
				convertedValue = {
					group_by: groupByColumn,
					value: {
						col_name: column.tagsNameList.col_name ?? '',
						values: valuesList,
						split: column.tagsNameList.split,
						only_to_first_appropriate: column.tagsNameList.split
							? column.tagsNameList.only_to_first_appropriate
							: false,
					},
					view_options: convertAdditionalParams(column.viewOptions),
				};
				break;

			case 'search_items':
				const searchItems = column.callFilters.activeValues.map((callFilter) => {
					const newSearchItems = criteriaWithComplexValues.includes(callFilter.key)
						? {
								key: callFilter.key,
								complexValues: callFilter.complexValues
									? callFilter.complexValues
									: callFilter.values.map((value: string) => JSON.parse(value)),
								logic: callFilter.logic || 'and',
						  }
						: {
								key: callFilter.key,
								values: callFilter.values,
						  };

					return newSearchItems;
				});

				convertedValue = {
					group_by: groupByColumn,
					value: {
						col_name: column.callFilters.nameColumn.value ?? '',
						search_items: searchItems,
					},
					view_options: convertAdditionalParams(column.viewOptions),
				};
				break;

			default:
				convertedValue = {
					group_by: groupByColumn,
					view_options: convertAdditionalParams(column.viewOptions),
				};
				break;
		}

		return convertedValue;
	});

	activeReport.cols_group_by = [...convertedColumns];
	return activeReport;
};

export const convertCallSearch = (callSearchArr: any, requestBody: any) => ({
	...requestBody,
	call_search_items: callSearchArr,
});

export const joinReportIdWithBodyReport = (requestBody: any, reportId: string | undefined) => {
	if (reportId) {
		return { ...requestBody, report_id: reportId };
	}
	return requestBody;
};

export const joinRows = (activeReport: ReportParametersType, additionalRows: RowsGroupByParametersTypeResponse[]) => ({
	...activeReport,
	rows_group_by: [...additionalRows],
});

export const requestHandler = (
	activeCriterias: CriteriasType[],
	activeReport: ReportParametersType,
	additionalRows: AdditionalRows[],
	reportId?: string | undefined,
) => {
	let requestData = [];
	const requestActiveCriterias = convertCriteriasForRequest(activeCriterias);

	const requestColsGroupBy = convertColumns(activeReport, activeReport.cols_group_by);

	const requestDataWithCallSearch = convertCallSearch([...requestActiveCriterias], requestColsGroupBy);

	const convertedAdditionalRows = convertAdditionalRows(additionalRows, requestDataWithCallSearch);

	const requestWithAdditionalRows = joinRows(requestDataWithCallSearch, convertedAdditionalRows);

	requestData = joinReportIdWithBodyReport(requestWithAdditionalRows, reportId);

	return requestData;
};
