import React, { useCallback, useMemo, useState } from 'react';
import { getGlobalStyles } from 'styles';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { CallTagType } from 'store/calls/calls.types';
import { useDispatch, useSelector } from 'react-redux';
import { callsActions } from 'store/calls';
import { RootState } from 'store/store';
import { TagGroup } from 'widgets/ManualTagging/types';
import { ISearchedWord } from '../TagsBlock/TagsBlock';
import { getFormattedTags } from '../../CallBody/utils';
import { callFragmentsLimit } from '../../constants';
import s from '../../styles.module.scss';
import { NewTagComponent } from 'shared/ui';

interface IOwnProps {
	expanded: boolean;
	tagsAndFragmentsArray: {
		tags: any[];
		fragments: any[];
		searchedWords?: ISearchedWord[];
	};
	setCurrentFragmentHandle: (value: CallTagType | ISearchedWord) => void;
	callId: string | undefined;
	solo: boolean | undefined;
	accessRights: boolean | undefined;
	deleteTag?: ((id: string) => Promise<void>) | undefined;
	tagGroup: TagGroup | null;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
	language: string;
}

const CallsFragments: React.FC<IOwnProps> = ({
	tagsAndFragmentsArray,
	expanded,
	setCurrentFragmentHandle,
	callId,
	solo,
	accessRights,
	deleteTag,
	tagGroup,
	clickedTagId,
	handleClickTagSettings,
	language,
}) => {
	const dispatch = useDispatch();
	const { setFragmentExpanded } = callsActions;
	const { blueTag, grayTag } = getGlobalStyles();
	const { trimmedTags, reducedTags } = getFormattedTags(tagsAndFragmentsArray.fragments, callFragmentsLimit);

	const expandedFragments = useSelector((state: RootState) => state.calls.expandedFragments);
	const showAllTags = callId ? expandedFragments?.[callId] || false : false;

	const tagsToDisplay = useMemo(() => {
		return showAllTags ? tagsAndFragmentsArray.fragments : trimmedTags;
	}, [showAllTags, tagsAndFragmentsArray.fragments, trimmedTags]);

	const handleShowMoreTags = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (callId) {
			dispatch(setFragmentExpanded({ callId, isExpanded: !showAllTags }));
		}
	};

	const handleAsyncClickTag = async (e: any, tag: CallTagType) => {
		setCurrentFragmentHandle(tag);
		if (expanded) {
			e.stopPropagation();
		}
	};

	return (
		<div className={s.container}>
			{(tagsAndFragmentsArray.searchedWords || []).map((word: ISearchedWord) => (
				<div
					key={word.fragment}
					className={blueTag}
					onClick={async (e) => {
						setCurrentFragmentHandle(word);
						if (expanded) {
							e.stopPropagation();
						}
					}}
				>
					{word.text}
				</div>
			))}
			{tagsToDisplay.map((fragment: { tag: CallTagType; count: number }) => {
				if (!solo && !fragment.tag.visible) {
					return null;
				}

				const className = solo && !fragment.tag.visible ? 'gray' : 'purple';

				return (
					<NewTagComponent
						id={fragment.tag.id}
						key={fragment.tag.name + fragment.tag.value}
						name={fragment.tag.name}
						value={fragment.tag.value ?? null}
						accessRights={accessRights}
						theme={className}
						count={fragment.count}
						cursor="pointer"
						language={language}
						settings={(solo || expanded) && true}
						isActiveTag={clickedTagId === fragment.tag.id && tagGroup === 'fragmentTop'}
						handleClickTag={handleClickTagSettings}
						deleteTag={deleteTag}
						tagGroup="fragmentTop"
						onClick={(e) => {
							if (e.target.tagName === 'path') {
								handleClickTagSettings(fragment.tag.id, 'call');
							} else {
								handleAsyncClickTag(e, fragment.tag);
							}
						}}
					/>
				);
			})}
			{reducedTags.length > 0 && (
				<button className={grayTag} onClick={handleShowMoreTags}>
					{showAllTags
						? translate('tagHide', language)
						: `${translate('show_more', language)} ${reducedTags.length}`}
				</button>
			)}
		</div>
	);
};

export default CallsFragments;
