import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { callsActions } from 'store/calls';
import { selectCurrentCall, selectIsCallsLoad } from 'store/calls/selectors';
import { getCheckListsByCallId, updateCurrentCallCheckListAnswerById } from 'store/checkLists/actions';
import { TUpdateCurrentCallCheckListAnswerById } from 'store/checkLists/namespaces/payloads';
import { getAllLanguages } from 'store/lang/lang.slice';
import { getUser } from 'store/user/user.slice';
import Call from '../Calls/Call';
import CallProgress from './components/CallProgress/CallProgress';
import CallBackToMain from './components/CallBackToMain/CallBackToMain';
import useCallData from './hooks/useCallData';
import { getTokens } from 'utils/tokens';
import { RouteParams } from 'pages/Settings/types';
import { selectCallsCheckLists } from 'store/checkLists/selectors';
import queryString from 'query-string';

interface CallPageProps {
	showOpenLink?: boolean;
	showParams?: boolean;
	callPageBoxStyles?: string;
	marginTop?: string;
	lengFromUrl?: string | null;
	idFromUrl?: string | null;
	tokenFromUrl?: string | null;
}

const CallPage = memo(
	({ showOpenLink = true, showParams = true, callPageBoxStyles, marginTop, lengFromUrl }: CallPageProps) => {
		const { lang } = useParams<RouteParams>();
		const currentLang = lengFromUrl || lang;

		const useStyles = makeStyles({
			callPageBox: {
				boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
			},
		});
		const classes = useStyles();

		const history = useHistory();
		const dispatch = useDispatch();

		const currentCall = useAppSelector(selectCurrentCall);
		const currentUser = useAppSelector((state) => state.user.user);
		const isAuth = useAppSelector((state) => state.user.isAuth);
		const accessRights = useAppSelector((state) => state.user.accessRights);
		const user = useAppSelector((state) => state.user.user);
		const checkLists = useAppSelector(selectCallsCheckLists);

		const isLoading = useAppSelector(selectIsCallsLoad);

		const [userInfo, setUserInfo] = useState(null);

		const { getCallDataById } = useCallData();

		useEffect(() => {
			if (user) {
				const fetchUserInfo = async () => {
					const userInfoFetch = await dispatch(getUser({ id: user.id })); // @ts-ignore
					setUserInfo(userInfoFetch.payload);
				};
				fetchUserInfo().catch(console.error);
			}
		}, [user]);

		const fetchCallInfoData = useCallback(async () => {
			const searchParamsObject = queryString.parse(history.location.search.slice(1));
			const id = searchParamsObject.id;
			// JWT токен из url
			const tokenFromUrl = searchParamsObject.token;
			// JWT токен, сохраненный при авторизации пользователя
			const { token } = getTokens();
			// если нет токена авторизации (пользователь не залогинен), используем токен из url
			const currentToken = token || tokenFromUrl;

			if (id && currentToken) {
				// @ts-ignore
				await getCallDataById(id, currentToken);
			} else {
				dispatch(callsActions.setCurrentCall(false));
			}
		}, []);

		// добавление параметров поиска
		useEffect(() => {
			fetchCallInfoData().then();
		}, []);

		useEffect(() => {
			document.title = 'Звонок | IMOT.io';
		}, []);

		useEffect(() => {
			document.title = `${translate('callDocTitle', currentLang)} | IMOT.io`;
		}, [currentLang]);

		const loadCheckListsByCallId = React.useCallback(
			(callId: string) => {
				dispatch(getCheckListsByCallId(callId));
			},
			[dispatch],
		);

		const updateCheckList = React.useCallback(
			(taget: TUpdateCurrentCallCheckListAnswerById): void => {
				dispatch(updateCurrentCallCheckListAnswerById(taget));
			},
			[dispatch],
		);

		useEffect(() => {
			dispatch(getAllLanguages());
		}, []);

		return (
			<div style={{ marginTop: marginTop || '40px' }}>
				{currentCall && (
					<div className={cn(classes.callPageBox, callPageBoxStyles)}>
						<Call
							showOpenLink={showOpenLink}
							showParams={showParams}
							updateCheckList={updateCheckList}
							loadCheckLists={loadCheckListsByCallId}
							callInfo={currentCall.info}
							index={null}
							solo
							expanded
							language={currentLang}
							isAuth={isAuth}
							accessRights={accessRights}
							isLoading={isLoading}
							userInfo={userInfo}
						/>
					</div>
				)}
				{currentCall === null && <CallProgress />}
				{currentCall === false && (
					<CallBackToMain language={currentLang} isAuth={isAuth} currentUser={currentUser} />
				)}
			</div>
		);
	},
);

export default CallPage;
