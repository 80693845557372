import React, { memo, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getAllSearchCriterias } from 'store/search/search.slice';
import { CriteriasType } from 'store/search/search.types';
import { PlusIcon } from 'shared/ui';
import { MinusSvg } from '../AddFilterSelect.svg';
import { useMuiTextSelectStyles } from '../AddFilterSelect.jss';

const CustomMenuList = memo(({ selectProps, ...props }: any) => {
	const classes = useMuiTextSelectStyles({ arrowColor: props.ifArrowColor });
	const dispatch = useAppDispatch();
	const allCriterias = useAppSelector((state) => state.search.allCriterias);

	useEffect(() => {
		const fetchAllCriteria = async () => {
			await dispatch(getAllSearchCriterias());
		};

		if (!allCriterias.length) fetchAllCriteria();
	}, [allCriterias]);

	if (!allCriterias.length)
		return (
			<div>
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
				<Skeleton height={35} width={345} />
			</div>
		);

	const Accordion = styled((props: AccordionProps) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		border: 'none !important',
		// marginBottom: '18px',
		// border: `1px solid ${theme.palette.divider}`,
		// '&:not(:last-child)': {
		//   borderBottom: 0,
		// },
		'&:before': {
			display: 'none',
		},
	}));

	const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(
		({ theme }) => ({
			backgroundColor: '#FFF',
			border: 'none !important',
			padding: '0px 12px',
			marginBottom: '3px',
			'& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
				transform: 'rotate(90deg)',
			},
			'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
				transform: 'rotate(180deg)',
			},
			minHeight: '0 !important',
			'& .MuiAccordionSummary-content': {
				margin: '0 !important',
			},
		}),
	);

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(2),
		// borderTop: '1px solid rgba(0, 0, 0, .125)',
	}));

	function sortCriteriasByGroups() {
		const groups: { groupName: string; criterias: CriteriasType[] }[] = [];

		allCriterias.forEach((criteria) => {
			let index = 0;
			const examination = groups.find((group) => {
				index = groups.indexOf(group);
				return group.groupName === criteria.groupName;
			});
			if (examination) {
				groups[index].criterias.push(criteria);
			} else {
				groups.push({ groupName: criteria.groupName, criterias: [criteria] });
			}
		});
		return groups;
	}

	const sortedCriteriasByGroups = sortCriteriasByGroups();

	function isCriteriasActive(criterias: CriteriasType[], activeCriteriasArray: CriteriasType[]): boolean {
		for (let i = 0; i < criterias.length; i++) {
			const currentCriteria = criterias[i];
			if (!activeCriteriasArray.find((activeCriteria) => activeCriteria.key === currentCriteria.key)) {
				return false;
			}
		}
		return true;
	}

	return (
		<div style={{ maxHeight: '400px' }}>
			{sortedCriteriasByGroups.map((group) => (
				<Accordion expanded key={group.groupName}>
					<AccordionSummary id={group.groupName}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<Typography className={classes.groupName}>{group.groupName}</Typography>
						</div>
					</AccordionSummary>
					<AccordionDetails style={{ padding: '0' }}>
						{group.criterias.map((criteria) => (
							<div
								key={criteria.key}
								onClick={() => {
									if (!isCriteriasActive([criteria], props.activeCriterias))
										props.setCriteria({ ...criteria, values: [], conditionItemId: 1 });
									else props.removeCriteria({ ...criteria, values: [], conditionItemId: 1 });
								}}
								className={classes.selectOption}
							>
								<Typography className={classes.groupItem}>{criteria.title}</Typography>
								{!isCriteriasActive([criteria], props.activeCriterias) ? (
									<span style={{ width: '12px', minWidth: '12px' }}>
										<PlusIcon />
									</span>
								) : (
									<span style={{ width: '12px', minWidth: '12px' }}>
										<MinusSvg style={{ width: '10px' }} />
									</span>
								)}
							</div>
						))}
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
});

export default CustomMenuList;
