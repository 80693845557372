import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'api/api.config';

export const sendReportNotifyAPI = createApi({
	reducerPath: 'sendReportNotifyAPI',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		// Возвращает список всех правил отправки уведомлений
		getAllReportNotifyRules: build.query({
			query: ({ report_id }) => ({
				url: `/reports/${report_id}/notifications`,
			}),
		}),
		// Возвращает переменные с шаблоном для подстановки в текст сообщения
		getSendReportNotifyVariables: build.query({
			query: () => ({
				url: `/reports_notify_rule_variables`,
			}),
		}),
		// Получает правило отправки уведомлений
		getReportNotifyRule: build.query({
			query: ({ report_id, rule_id }) => ({
				url: `/reports/${report_id}/notifications/${rule_id}`,
			}),
		}),
		// Обновляет правило отправки уведомлений
		patchReportNotifyRule: build.mutation({
			query: ({ report_id, rule_id, params }) => ({
				url: `/reports/${report_id}/notifications/${rule_id}`,
				method: 'PATCH',
				body: params,
			}),
		}),
		// Обновляет правило отправки уведомлений
		deleteReportNotifyRule: build.mutation({
			query: ({ report_id, rule_id }) => ({
				url: `/reports/${report_id}/notifications/${rule_id}`,
				method: 'DELETE',
			}),
		}),
		// Создает правило отправки уведомлений
		createReportNotifyRule: build.mutation({
			query: ({ report_id, params }) => ({
				url: `/reports/${report_id}/notifications`,
				method: 'POST',
				body: params,
			}),
		}),
	}),
});
