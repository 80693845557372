import { manualTaggingAPI } from 'services/manualTaggingService';

export const useManualTaggingService = (callId: string) => {
	const [addManualTag, { isError: isErrorAddManual, isLoading: isLoadingAddManual, isSuccess: isSuccessAddManual }] =
		manualTaggingAPI.useAddManualTagMutation();
	const [
		deleteManualTag,
		{ isError: isErrorDeleteManual, isLoading: isLoadingDeleteManual, isSuccess: isSuccessDeleteManual },
	] = manualTaggingAPI.useDeleteManualTagMutation();

	const [
		restoreHiddenTag,
		{ isError: isErrorRestoreTag, isLoading: isLoadingRestoreTag, isSuccess: isSuccessRestoreTag },
	] = manualTaggingAPI.useRestoreHiddenTagMutation();

	const { data: manualTags, refetch } = manualTaggingAPI.useGetManualTagsQuery(null);

	const {
		refetch: refetchHiddenTags,
		data: hiddenTags,
		isLoading: isLoadingHiddenTags,
		isError: isErrorHiddenTags,
	} = manualTaggingAPI.useGetHiddenTagsQuery(callId);

	return {
		data: {
			isLoadingAddManual,
			isErrorAddManual,
			isSuccessAddManual,
			tagsListForSelect: manualTags,

			isErrorDeleteManual,
			isLoadingDeleteManual,
			isSuccessDeleteManual,

			isErrorRestoreTag,
			isLoadingRestoreTag,
			isSuccessRestoreTag,

			hiddenTags,
			isLoadingHiddenTags,
			isErrorHiddenTags,
		},
		methods: {
			// ручные теги
			addManualTag,
			deleteManualTag,
			updateManualTags: refetch,

			// удаленные теги
			getHiddenTags: manualTaggingAPI.useGetHiddenTagsQuery,
			restoreHiddenTag,
			refetchHiddenTags,
		},
	};
};
