import { FC, useEffect, useState } from 'react';
import { OptionType } from 'components/common/Selects/LabelSelect/LabelSelect';
import MultiValueSelect from 'components/common/Selects/MultiValueSelect/MultiValueSelect';
import cn from 'classnames';
import { Tooltip } from 'components/common';
import { TooltipPropsType } from 'components/common/Tooltip/Tooltip';
import { IconQuestion } from 'components/Icons/IconQuestion';
import { ErrorsArr } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';

import cls from './styles.module.scss';

interface MultiSelectWithHeaderProps {
	testId?: string;
	title: string;
	options: OptionType[];
	initialValue?: OptionType[];
	onSelectedChatsChange?: (newSelectedChats: OptionType[]) => void;
	disabled?: boolean;
	className?: string;
	required?: boolean;
	name?: string;
	classNameLabel?: string;
	tooltipTitle?: string;
	tooltipPlacement?: TooltipPropsType['placement'];
	tooltipTheme?: 'dark' | 'white';
	errorsArr?: ErrorsArr;
}

const MultiSelectWithHeader: FC<MultiSelectWithHeaderProps> = (props) => {
	const {
		testId,
		title,
		options,
		initialValue,
		onSelectedChatsChange,
		disabled,
		className,
		required,
		name,
		classNameLabel,
		tooltipTitle,
		tooltipPlacement,
		tooltipTheme,
		errorsArr,
	} = props;

	const [selectedOption, setSelectedOption] = useState<OptionType[]>(initialValue || []);

	useEffect(() => {
		if (initialValue) {
			setSelectedOption(initialValue);
		}
	}, [initialValue, options]);

	const handleValueChange = (value: OptionType[]): void => {
		setSelectedOption(value);

		if (onSelectedChatsChange) {
			onSelectedChatsChange(value);
		}
	};

	return (
		<div className={`${cls.root} ${className}`}>
			{title && (
				<label htmlFor={name} className={cn(cls.label, classNameLabel)}>
					{title}
					{required && <span className={cls.fieldRequired}>*</span>}
					{tooltipTitle && (
						<Tooltip
							title={tooltipTitle}
							placement={tooltipPlacement || 'top'}
							background={tooltipTheme || 'white'}
						>
							<IconQuestion />
						</Tooltip>
					)}
				</label>
			)}

			<MultiValueSelect
				testId={testId}
				criteriaKey=""
				width="100%"
				value={selectedOption}
				options={options}
				selectType="multiString"
				valueHandler={handleValueChange}
				disabled={disabled}
				errorsArr={errorsArr}
			/>
		</div>
	);
};

export default MultiSelectWithHeader;
