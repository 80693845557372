import React, { FC, ReactNode } from 'react';
import { TagThemesTypes } from 'pages/Calls/types';
import { MenuTag } from 'shared/ui/NewTagComponent/type';
import EditIconSvg from 'shared/ui/icons/general/EditIcon/EditIcon';
import { TagGroup } from 'widgets/ManualTagging/types';
import TagMenu from './TagMenu';
import s from './style.module.scss';
import { themesColors } from '../../const/consts';

interface IconsForTagProps {
	menu: MenuTag[];
	id: string | null | undefined;
	active: boolean | undefined;
	accessRights: boolean | undefined;
	theme: TagThemesTypes;
	isHover: boolean;
	language: string;
	children: ReactNode;
	handleClickTag: (id: string | null, tagGroup: TagGroup | null) => void;
	tagTablet?: boolean;
	isActiveTag: boolean; // флаг активного тега по клику
	isLoading?: boolean;
	tagGroup: TagGroup | null;
}

const IconsForTag: FC<IconsForTagProps> = ({
	id,
	theme,
	active,
	isHover,
	language,
	tagTablet,
	isActiveTag,
	accessRights,
	children,
	handleClickTag,
	menu,
	isLoading,
	tagGroup,
}) => {
	return (
		<div className={active ? s.tagHover : ''}>
			{children}
			{active && accessRights && (
				<div
					className={tagTablet ? s.settingsForTablet : s.settings}
					style={{ opacity: isHover || isActiveTag ? 1 : 0 }}
				>
					<div
						onClick={(e) => {
							handleClickTag(id ?? null, tagGroup ?? null);
							// handleClickTag(isActiveTag && id ? null : id || null);
							e.stopPropagation();
						}}
					>
						<TagMenu
							menu={menu}
							language={language}
							isOpen={isActiveTag}
							isLoading={isLoading}
							handleTooltipClose={() => handleClickTag(null, null)}
						>
							<EditIconSvg color={themesColors[theme].color} bg={themesColors[theme].bg} />
						</TagMenu>
					</div>
				</div>
			)}
		</div>
	);
};

export default React.memo(IconsForTag);
