import { FC, SVGProps } from 'react';

const NoDataIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg width="164" height="164" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect
			x="94.5618"
			y="84.7927"
			width="41.9123"
			height="41.9123"
			rx="7.4825"
			stroke="#2F3747"
			stroke-width="1.36667"
		/>
		<rect
			x="94.5618"
			y="36.613"
			width="41.9123"
			height="41.9123"
			rx="7.4825"
			stroke="#2F3747"
			stroke-width="1.36667"
		/>
		<rect
			x="46.383"
			y="84.7927"
			width="41.9123"
			height="41.9123"
			rx="7.4825"
			stroke="#2F3747"
			stroke-width="1.36667"
		/>
		<g filter="url(#filter0_b_10801_74941)">
			<rect
				x="30.772"
				y="84.1094"
				width="43.2789"
				height="43.2789"
				rx="8.16583"
				transform="rotate(15 30.772 84.1094)"
				fill="#E3E8EF"
				fill-opacity="0.64"
			/>
		</g>
		<circle cx="59.2023" cy="102.888" r="7.34925" fill="white" />
		<path
			d="M46.4929 123.173L25.8747 131.539C24.499 132.098 24.5215 134.053 25.9096 134.58L33.1831 137.339C33.6139 137.502 33.9556 137.84 34.124 138.269L36.9037 145.344C37.4365 146.7 39.3438 146.733 39.9226 145.396L48.6058 125.335C49.1864 123.993 47.8474 122.623 46.4929 123.173Z"
			fill="#9254DE"
		/>
		<path
			d="M27.7679 17.1484C45.8938 29.9726 71.1583 28.2238 71.1583 28.2238C71.1583 28.2238 21.3967 56.4952 20.0785 69.3193C18.4307 85.3495 45.3437 85.058 67.3135 71.651C89.2834 58.2439 78.2993 48.6258 61.8219 55.9123C45.3446 63.1987 34.3597 78.3545 33.2612 89.4299C32.3824 98.2902 34.3597 108.083 35.4582 111.872"
			stroke="#2F3747"
			stroke-width="1.36667"
		/>
		<defs>
			<filter
				id="filter0_b_10801_74941"
				x="8.33848"
				y="72.8761"
				width="75.47"
				height="75.4705"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="6.53267" />
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_10801_74941" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_10801_74941" result="shape" />
			</filter>
		</defs>
	</svg>
);

export default NoDataIcon;
